import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';
import styles from './styles';
import packageJson from '../../../package.json';

const Footer: FC = () => {
  return (
      <Box
        sx={styles.footerContainer}
      >
        <Typography tabIndex={0} sx={styles.footerVersion}>
          {`v${packageJson.version}`}
        </Typography>
        <Typography sx={styles.supportContainer}>
          Need assistance? Visit our
          &nbsp;
          <Link
            underline="hover"
            color="#0E55AA"
            href="https://hoolisoftware.atlassian.net/servicedesk/customer/portal/3/group/-1"
            target="_blank"
          >
            LoanWatch Support Portal
          </Link>
          &nbsp;
          or email us at
          &nbsp;
          <Link
            underline="hover"
            color="#0E55AA"
            href="mailto:lwsupport@hoolisoftware.atlassian.net"
          >
            lwsupport@hoolisoftware.atlassian.net
          </Link>
        </Typography>
      </Box>
  );
};

export default Footer;