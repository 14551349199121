import colors from "../../../../utility/colors"

const styles = {
  searchField: {
    backgroundColor: colors.WHITE,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.NEUTRAL,
      borderRadius: "0px 4px 4px 0px",
    },
    '& .MuiOutlinedInput-root': {
      color: '#000',
      padding: '15px',
      fontSize: '14px',
      height: '36.6px',
      backgroundColor: colors.WHITE,
      '& .MuiOutlinedInput-input': {
        width: '100%',
        padding: '0px',
      },
    },
    '& .Mui-disabled': {
      backgroundColor: colors.DISABLED,
    },
    width          : '70%',
  },
  searchAndFilterContainer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    flexBasis: 'auto',
    width: '40%',
  },
  filterStyle: {
    height: '36.6px',
    width: '30%',
    borderRadius: "4px 0px 0px 4px",
    backgroundColor: colors.WHITE
  }
}
export default styles;