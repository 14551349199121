import colors from "../../../../../utility/colors";

const styles = {
  tableCell: {
    fontSize: '0.9rem',
    border: 0,
    color: 'inherit',
    height: '2.5rem',
    '&:nth-child(2)': {
      pl: '0.5rem',
    },
    '&:last-child': {
      paddingRight: '3rem',
    },
  },
  tableCellAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableCellNewTag: {
    fontSize: '0.9rem',
    border: 0,
    color: 'inherit',
    height: '2.5rem',
    p: 0,
    textDecoration: 'none',
    cursor: 'auto'
  },
  tableCellCollapse: {
    padding: 0,
    border: 0,
  },
  tableRow: {
    backgroundColor: '#F7F7F7',
    '&:hover': {
      backgroundColor: 'rgba(235,235,235,0.5)',
      color: '#0075AD',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    '&:nth-of-type(2n)': {
      backgroundColor: 'inherit',
    },
  },
  tableRowChildVendor: {
    backgroundColor: '#F7F7F7',
    '&:nth-of-type(2n)': {
      backgroundColor: 'inherit',
    },
  },
  tableRowChild: {
    backgroundColor: '#F7F7F7',
  },
  tableRowVendor: {
    backgroundColor: colors.WHITE,
  },
  newTagBox: { 
    display: 'flex', 
    justifyContent: 'right', 
    width: '41px', 
    textDecoration: 'none' 
  },
  iconBox: { 
    display: 'flex',
    paddingLeft: '0.5rem',
    width: '41px', 
    textDecoration: 'none' 
  },
  custName: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '30ch'
  },
  count: {
    fontSize: '0.9rem',
    color: 'inherit',
    whiteSpace: 'nowrap',
    marginLeft: '0.3rem',
  },
  parentIdentifierChip: {
    marginLeft: '15px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    '& .MuiChip-label:after': {
      content: '"P"',
    },
    '& .MuiChip-label:hover:after': {
      content: '"Parent"',
    }
  },
  isLoading: {
    marginTop: '0.3rem',
    marginX: '0.6rem',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '0.5rem',
  },
  circularProgress: {
    marginLeft: '0.8rem',
  },
  tableHead: {
    backgroundColor: colors.NEUTRAL_LIGHT,
    color: 'inherit',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    border: 0,
    height: '2.5rem',
    '&:nth-child(2)': {
      pl: '0.5rem',
    },
    '&:last-child': {
      p: 0,
      paddingRight: '3rem',
    },
  },
  tableHeadSelectTag: {
    backgroundColor: colors.NEUTRAL_LIGHT,
    color: 'inherit',
    fontSize: '0.9rem',
    border: 0,
    height: '2.5rem',
    p: 0,
    paddingLeft: '0.5rem',
  },
}
export default styles;