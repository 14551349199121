import { TableRow, TableCell, Box, CircularProgress, Tooltip, IconButton, Typography } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import styles from '../styles'
import { IARCustomer, IARVendor } from '../../../../../../interfaces'
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../../../../context/customerSettingsContext'
import PreviewIcon from '@mui/icons-material/Preview';
import { SelectedClientContext } from '../../../../../../context/selectedClientContext'
import { CustomerVendorRowProps } from '..'
import { formatDate, getUniqueKeys } from '../../../../../../utility/helper'
import NewTag from '../../../../../../components/common/new-tag'
import CustomerVendorModal from '../../../../customer-vendor-modal'
import { FormikHelpers } from 'formik'
import customerSchema from '../../../../../../schemas/customerSchema'
import vendorSchema from '../../../../../../schemas/vendorSchema'
import { AMERICAN_DATE_FORMAT } from '../../../../../../utility/constants'

interface ChildrenRowProps extends CustomerVendorRowProps {
  childVendor: IARCustomer | IARVendor;
  getCustName: (custName: string, parentTag?: React.ReactNode) => JSX.Element;
  fetchCustomers: (borrowerId?: number, arCollateralId?: number) => Promise<IARCustomer[]>;
  fetchVendors: (borrowerId?: number, arCollateralId?: number) => Promise<IARVendor[]>;
  handleSaveCustomer: (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => Promise<void>;
  handleSaveVendor: (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => Promise<void>;
}

const ChildrenVendorRow = (props: ChildrenRowProps) => {
  const {
    childVendor,
    getCustName,
    fetchCustomers,
    fetchVendors,
    headers,
    handleSaveCustomer,
    handleSaveVendor,
    viewByCustomer
  } = props;

  const {
    canViewCustomerInformation,
    setToaster,
    selectedARCollateral
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const { selectedClient } = useContext(SelectedClientContext);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [fullListForModal, setFullListForModal] = useState<IARCustomer[] | IARVendor[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const isVendor = (obj: any): obj is IARVendor => {
    return 'vendorName' in obj;
  }

  const tableLabel = useMemo(() => 
    isVendor(childVendor) ? 'Vendor' : 'Customer'
  , [childVendor])

  const updatedCustomerList = useMemo(() => {
    const removedSelf = (fullListForModal as IARCustomer[]).filter((value: IARCustomer) => value.recordId !== childVendor.recordId);
    return removedSelf;
  }, [fullListForModal, childVendor])

  const updatedVendorList = useMemo(() => {
    const removedSelf = (fullListForModal as IARVendor[]).filter((value: IARVendor) => value.recordId !== childVendor.recordId);
    return removedSelf;
  }, [fullListForModal, childVendor])

  const rowCells = useMemo(() => 
    headers.map((header) => {
      if (isVendor(childVendor) && (header.id === 'custName')) {
        return childVendor['vendorName'] ?? '-';
      } else if (isVendor(childVendor) && (header.id === 'custSrcId')) {
        return childVendor['vendorSrcId'] ?? '-';
      } else if (header.type === 'string') {
        return childVendor[header.id] ?? '-';
      } else {
        return childVendor[header.id] ? formatDate(childVendor[header.id], AMERICAN_DATE_FORMAT) : '-';
      }
    })
  , [childVendor])

  const handleOpenModal = async () => {
    try {
      setIsLoading(true);

      const list = isVendor(childVendor) ?
        await fetchVendors(selectedClient?.recordId, selectedARCollateral?.recordId) :
        await fetchCustomers(selectedClient?.recordId, selectedARCollateral?.recordId);
        
      setFullListForModal(list);
      setOpen(true);
    } catch (error) {
      console.log('ERROR FETCHING FULL LIST: ', error);
      setToaster({ open: true, message: 'Failed to fetch list!', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditting(false);
  }

  const getRowStyle = () => {
    if (viewByCustomer && isVendor(childVendor)) {
      return styles.tableRowVendor;
    } else if (viewByCustomer && !isVendor(childVendor)) {
      return styles.tableRowChild;
    } else {
      return styles.tableRowChildVendor
    }
  }
  
  return (
    <>
    <TableRow sx={getRowStyle()}>
      <TableCell sx={styles.tableCellNewTag}>
        <Box sx={styles.newTagBox}>
          {childVendor.isNew &&
            <Typography><NewTag expanding /></Typography>}
        </Box>
      </TableCell>
      <TableCell sx={styles.tableCellNewTag}>
        <Box sx={styles.iconBox} />
      </TableCell>
      {rowCells.map((rowHeader) =>
        <TableCell 
          width={'20%'} 
          tabIndex={0}
          key={getUniqueKeys(rowHeader)}
          sx={styles.tableCell}
          >
          {getCustName(rowHeader)}
        </TableCell>
      )}
      {
        canViewCustomerInformation && 
        <TableCell
          sx={{
            ...styles.tableCell,
            ...styles.tableCellAction,
          }}
        >
          {isLoading ? 
            <Box sx={styles.isLoading}>
              <CircularProgress size={15} />
            </Box>
            :
            <Tooltip title={`View ${tableLabel} Details`}>
              <IconButton
                aria-label={`View ${tableLabel} details icon`}
                color='primary'
                size='small'
                onClick={handleOpenModal}
              >
                <PreviewIcon />
              </IconButton>
            </Tooltip>}
        </TableCell>
      }
    </TableRow>

    {isVendor(childVendor) ?
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={childVendor}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveVendor}
        onClose={handleClose}
        schema={vendorSchema(updatedVendorList)}
      />
      :
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={childVendor}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveCustomer}
        onClose={handleClose}
        schema={customerSchema(updatedCustomerList, false, selectedClient?.parentClient)}
      />
    }
    </>
  )
}

export default ChildrenVendorRow