import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
import { IARCustomer, IARVendor } from '../interfaces';

interface ActionPanelProps {
  open: boolean;
  restore: boolean;
}

interface IClearModalProps {
  isOpen: boolean;
  type: 'customer' | 'vendor' | 'all';
}

interface ActionModalProps {
  recordIds: number[];
  dataType: string;
  actionType: 'delete' | 'archive' | 'restore';
  title: string;
  description: string;
  open: boolean;
  yesButtonText: string;
  noButtonText: string;
  errorButton: boolean;
}

interface WarningModalProps {
  deleteRecordIds: number[];
  archiveRecordIds: number[];
  dataType: 'customer' | 'vendor';
  description: string;
  open: boolean;
}

interface ISortParams {
  sortBy: string;
  direction: string;
}

const initialActionModal: ActionModalProps = { 
  recordIds: [],
  dataType: 'customer',
  actionType: 'delete',
  title: '', 
  description: '', 
  open: false, 
  yesButtonText: 'Proceed', 
  noButtonText: 'Cancel', 
  errorButton: false, 
}

const initialWarningModal: WarningModalProps = {
  deleteRecordIds: [],
  archiveRecordIds: [],
  dataType: 'customer',
  description: '', 
  open: false,
}

export interface ICustomerSettingsMainViewContext {
  actionPanel: ActionPanelProps;
  setActionPanel: Dispatch<SetStateAction<ActionPanelProps>>;
  clearModal: IClearModalProps;
  setClearModal: Dispatch<SetStateAction<IClearModalProps>>;
  selectAll: boolean;
  setSelectAll: Dispatch<SetStateAction<boolean>>;
  customerVendorList: IARCustomer[] | IARVendor[];
  setCustomerVendorList: Dispatch<SetStateAction<IARCustomer[] | IARVendor[]>>;
  customerVendorCount: {[key: number]: number};
  setCustomerVendorCount: Dispatch<SetStateAction<{[key: number]: number}>>;
  actionModal: ActionModalProps;
  setActionModal: Dispatch<SetStateAction<ActionModalProps>>;
  warningModal: WarningModalProps;
  setWarningModal: Dispatch<SetStateAction<WarningModalProps>>;
  displayNoTable: boolean;
  setDisplayNoTable: Dispatch<SetStateAction<boolean>>;
  isFetching: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  custSortParams: ISortParams;
  setCustSortParams: Dispatch<SetStateAction<ISortParams>>;
  vendorSortParams: ISortParams;
  setVendorSortParams: Dispatch<SetStateAction<ISortParams>>;
  triggerSearch: boolean;
  setTriggerSearch: Dispatch<SetStateAction<boolean>>;
  triggerSelectAll: boolean;
  setTriggerSelectAll: Dispatch<SetStateAction<boolean>>;
}

export const CustomerSettingsMainViewContext = createContext<ICustomerSettingsMainViewContext | null>(null);

const CustomerSettingsMainViewProvider = (props: LayoutProps) => { 
  const { children } = props;
  
  const [actionPanel, setActionPanel] = useState<ActionPanelProps>({ open: false, restore: false, });
  const [clearModal, setClearModal] = useState<IClearModalProps>({ isOpen: false, type: 'all' });
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [customerVendorList, setCustomerVendorList] = useState<IARCustomer[] | IARVendor[]>([]);
  const [customerVendorCount, setCustomerVendorCount] = useState<{[key: number]: number}>({});
  const [actionModal, setActionModal] = useState<ActionModalProps>(initialActionModal);
  const [warningModal, setWarningModal] = useState<WarningModalProps>(initialWarningModal);
  const [displayNoTable, setDisplayNoTable] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [custSortParams, setCustSortParams] = useState<ISortParams>({sortBy: 'custName', direction: 'ASC'});
  const [vendorSortParams, setVendorSortParams] = useState<ISortParams>({sortBy: 'vendorName', direction: 'ASC'});
  const [triggerSearch, setTriggerSearch] = useState<boolean>(false);
  const [triggerSelectAll, setTriggerSelectAll] = useState<boolean>(false);
  
  const value = useMemo(() => ({
    actionPanel, setActionPanel,
    clearModal, setClearModal,
    selectAll, setSelectAll,
    customerVendorList, setCustomerVendorList,
    customerVendorCount, setCustomerVendorCount,
    actionModal, setActionModal,
    warningModal, setWarningModal,
    displayNoTable, setDisplayNoTable,
    isFetching, setIsFetching,
    custSortParams, setCustSortParams,
    vendorSortParams, setVendorSortParams,
    triggerSearch, setTriggerSearch,
    triggerSelectAll, setTriggerSelectAll,
  }), [
    actionPanel, setActionPanel,
    clearModal, setClearModal,
    selectAll, setSelectAll,
    customerVendorList, setCustomerVendorList,
    customerVendorCount, setCustomerVendorCount,
    actionModal, setActionModal,
    warningModal, setWarningModal,
    displayNoTable, setDisplayNoTable,
    isFetching, setIsFetching,
    custSortParams, setCustSortParams,
    vendorSortParams, setVendorSortParams,
    triggerSearch, setTriggerSearch,
    triggerSelectAll, setTriggerSelectAll,
   ])

  return (
    <CustomerSettingsMainViewContext.Provider
      value={value}
    >
      {children}
    </CustomerSettingsMainViewContext.Provider>
  );
};

export default CustomerSettingsMainViewProvider;