import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import { EditVendorCustomerContext, IEditVendorCustomerContext } from "../../../context/editVendorCustomerContext";
import { IARCustomer, IARVendor, IClient } from "../../../interfaces";
import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import axiosInstance from "../../../service/axiosInstance";
import { arCustomersAPI, arVendorsAPI } from "../../../service/api";
import { GET, PUT } from "../../../utility/constants";
import { ICustomerWithChildAndVendors } from "../../../interfaces/editVendorCustomerInterface";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import editVendorCustomerSchema from "../../../schemas/editVendorCustomerSchema";
import ConfirmModal from "../../../components/modals/confirm-modal";
import CustomersSection from "./customers-section";
import VendorsSection from "./vendors-section";
import { clearNewCustomersByIds } from "../../../utility/helper";

export interface EditVendorCustomerProps {

}

export interface MainSectionProps extends EditVendorCustomerProps {
  formik: FormikProps<{
    vendorsToEdit: IARVendor[];
  }>;
}

// Functions
export const getNameAndSrcId = (customerOrVendor: any, type: 'customer' | 'vendor') => {
  const customerOrVendorName: string = type === 'customer' ? customerOrVendor.custName : customerOrVendor.vendorName;
  const customerOrVendorSrcId: string = type === 'customer' ? customerOrVendor.custSrcId : customerOrVendor.vendorSrcId;

  if (customerOrVendorName.length >= 37) {
    return (
      <Tooltip
        title={customerOrVendorName}
        placement='bottom-start'
      >
        <Box>
          <Typography
            tabIndex={0}
            id={customerOrVendorSrcId}
            sx={styles.customerOrVendorName}
          >
            {customerOrVendorName}
          </Typography>
          <Typography
            tabIndex={0}
            sx={styles.customerOrVendorSrcId}
          >
            {`${type === 'customer' ? 'Customer' : 'Vendor'} ID: ${customerOrVendorSrcId}`}
          </Typography>
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <>
      <Typography
        tabIndex={0}
        id={customerOrVendorSrcId}
        sx={styles.customerOrVendorName}
      >
        {customerOrVendorName}
      </Typography>
      <Typography
        tabIndex={0}
        sx={styles.customerOrVendorSrcId}
      >
        {`${type === 'customer' ? 'Customer' : 'Vendor'} ID: ${customerOrVendorSrcId}`}
      </Typography>
      </>
    )
  }
}

const EditVendorCustomer = (props: EditVendorCustomerProps) => {
  const navigate = useNavigate();

  // Contexts
  const {
    canEditParentChildRelationship,
    setToaster,
    selectedARCollateral
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const { selectedClient } = useContext(SelectedClientContext);

  const {
    setInitialVendors,
    isFetching, setIsFetching,
    setCustomersList,
    setFilteredCustomersList,
    setVendorsList,
    setFilteredVendorsList,
    triggerResetSearch, setTriggerResetSearch,
    setSelectedVendors,
  } = useContext(EditVendorCustomerContext) as IEditVendorCustomerContext;

  // States
  const [triggerFetching, setTriggerFetching] = useState<boolean>(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState<boolean>(false);

  // Memo
  const isUltimateParent = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient])

  // Effects
  useEffect(() => {
    // Fetching initial data
    if (selectedClient === null) return;

    fetchData(selectedClient);
  }, [selectedClient, selectedARCollateral, triggerFetching]);

  // Functions
  const fetchData = async (selectedClient: IClient) => {
    setIsFetching(true);
    if (isUltimateParent) {
      await fetchUpcVendors(selectedClient.recordId as number);
    } else if (selectedARCollateral) {
      await fetchArVendors(selectedARCollateral.recordId);
    }
    setIsFetching(false);
  }

  const fetchUpcVendors = async (borrowerId: number) => {
    try {
      // Fetching Customers
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const customers: IARCustomer[] = custResponse.data.content;

      // Fetching Ar Amounts
      const arAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_PARENT_BORROWER_ID,
        method: GET,
        params: { parentBorrowerId: borrowerId }
      });
      const arAmountMap = arAmountResponse.data;

      // Merging Customer With Amounts
      const customersWithAmounts = customers.map(customer => {
        const recordId = customer.recordId as number;
        customer.arAmount = arAmountMap[recordId];
        return customer;
      });

      // Fetching Vendors
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;

      // Fetching Ap Amounts
      const apAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AP_AMOUNT_MAP_BY_PARENT_BORROWER_ID,
        method: GET,
        params: { parentBorrowerId: borrowerId }
      });
      const apAmountMap = apAmountResponse.data;

      // Merging Vendors With Amounts
      const vendorsWithAmounts = vendors.map(vendor => {
        const recordId = vendor.recordId as number;
        vendor.apAmount = apAmountMap[recordId];
        return vendor;
      });
      setInitialVendors(vendorsWithAmounts);

      // Reducing the Array
      const reducedArray = getUpcParentsChildrenAndVendors(customersWithAmounts, vendorsWithAmounts);
      setCustomersList(reducedArray.customerSection);
      setFilteredCustomersList(reducedArray.customerSection);
      setVendorsList(reducedArray.vendorSection);
      setFilteredVendorsList(reducedArray.vendorSection);
    } catch (error) {
      console.log('FETCH UPC VENDORS ERROR: ', error);
    }
  }

  const getUpcParentsChildrenAndVendors = (
    customers: IARCustomer[],
    vendors: IARVendor[]
  ) => {
    // Get the Parent, Child, and Linked Vendors
    const customerSection: ICustomerWithChildAndVendors[] = customers.reduce((prevValue, customer, _, array) => {
      if (!customer.archive) {
        // Getting parent, children, and vendors
        if (customer.upcParentCustSrcId) {
          const linkedVendors = vendors.filter(vendor => !vendor.archive && vendor.arCustomerId === customer.upcParentCustId);

          // Check if parent is existing in the current parentsWithChildren
          const existingCustomer: ICustomerWithChildAndVendors | undefined = prevValue
            .find(existing => existing.custSrcId === customer.upcParentCustSrcId);

          if (existingCustomer) {
            // Add to the customer and vendors array of the parent
            existingCustomer.childCustomers.push(customer);
          } else {
            // Get record for isNew
            const parentRecord = array.find(parent => parent.recordId === customer.upcParentCustId);

            // Create a new parent with child
            const customerWithChildAndVendors: ICustomerWithChildAndVendors = {
              recordId: customer.upcParentCustId,
              custSrcId: customer.upcParentCustSrcId,
              custName: customer.upcParentCustName!,
              // always have upc parent cust id since this is from the database already
              totalArAmount: 0.0, // initial amount
              isNew: parentRecord?.isNew,
              isParent: true,
              childCustomers: [ customer ],
              vendors: linkedVendors
            };

            prevValue.push(customerWithChildAndVendors);
          }
        }

        // Getting orphans, and vendors
        if (!customer.upcParentCustSrcId && !customer.isUpcParent) {
          const linkedVendors = vendors.filter(vendor => !vendor.archive && vendor.arCustomerId === customer.recordId);

          // INVARIANCE: No need to check existing customer from the prev array since there is
          // uniqueness in the custSrcId of the customers

          const customerWithChildAndVendors: ICustomerWithChildAndVendors = {
            recordId: customer.recordId,
            custSrcId: customer.custSrcId!,
            custName: customer.custName!,
            // always have cust src id and cust name since this is from the database already
            isNew: customer.isNew,
            totalArAmount: customer.arAmount,
            childCustomers: [],
            vendors: linkedVendors
          };

          prevValue.push(customerWithChildAndVendors);
        }
      }

      return prevValue;
    }, [] as ICustomerWithChildAndVendors[]);

    // Get the Unlinked Vendors
    const vendorSection = vendors.filter(vendor => !vendor.archive && !vendor.arCustomerId);

    return { customerSection, vendorSection };
  }

  const fetchArVendors = async (arCollateralId: number) => {
    try {
      // Fetching Customers
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: {
          arCollateralId,
          sortBy: 'custName,ASC'
        }
      });
      const customers: IARCustomer[] = custResponse.data.content;

      // Fetching Ar Amounts
      const arAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const arAmountMap = arAmountResponse.data;

      // Merging Customer With Amounts
      const customersWithAmounts = customers.map(customer => {
        const recordId = customer.recordId as number;
        customer.arAmount = arAmountMap[recordId];
        return customer;
      });

      // Fetching Vendors
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;

      // Fetching Ap Amounts
      const apAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AP_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId: selectedARCollateral?.recordId }
      })
      const apAmountMap = apAmountResponse.data;

      // Merging Vendors With Amounts
      const vendorsWithAmounts = vendors.map(vendor => {
        const recordId = vendor.recordId as number;
        vendor.apAmount = apAmountMap[recordId];
        return vendor;
      });
      setInitialVendors(vendorsWithAmounts);
      
      // Reducing the Array
      const reducedArray = getArParentsChildrenAndVendors(customersWithAmounts, vendorsWithAmounts);
      setCustomersList(reducedArray.customerSection);
      setFilteredCustomersList(reducedArray.customerSection);
      setVendorsList(reducedArray.vendorSection);
      setFilteredVendorsList(reducedArray.vendorSection);
    } catch (error) {
      console.log('FETCH AR VENDORS ERROR: ', error);
    }
  }

  const getArParentsChildrenAndVendors = (
    customers: IARCustomer[],
    vendors: IARVendor[]
  ) => {
    // Get the Parent, Child, and Linked Vendors
    const customerSection: ICustomerWithChildAndVendors[] = customers.reduce((prevValue, customer, _, array) => {
      if (!customer.archive) {
        // Getting parent, children, and vendors
        if (customer.parentCustSrcId) {
          const linkedVendors = vendors.filter(vendor => !vendor.archive && vendor.arCustomerId === customer.parentARCustomerId);
          
          // Check if parent is existing in the current parentsWithChildren
          const existingCustomer: ICustomerWithChildAndVendors | undefined = prevValue
            .find(existing => existing.custSrcId === customer.parentCustSrcId);

          if (existingCustomer) {
            // Add to the customer and vendors array of the parent
            existingCustomer.childCustomers.push(customer);
          } else {
            // Get record for isNew
            const parentRecord = array.find(parent => parent.recordId === customer.parentARCustomerId);

            // Create a new parent with child
            const customerWithChildAndVendors: ICustomerWithChildAndVendors = {
              recordId: customer.parentARCustomerId,
              custSrcId: customer.parentCustSrcId,
              custName: customer.parentCustName!,
              // always have upc parent cust id since this is from the database already
              totalArAmount: 0.0, // initial amount
              isNew: parentRecord?.isNew,
              isParent: true,
              childCustomers: [ customer ],
              vendors: linkedVendors
            };

            prevValue.push(customerWithChildAndVendors);
          }
        }

        // Getting orphans, and vendors
        if (!customer.parentCustSrcId && !customer.isCustParent) {
          const linkedVendors = vendors.filter(vendor => !vendor.archive && vendor.arCustomerId === customer.recordId);

          // INVARIANCE: No need to check existing customer from the prev array since there is
          // uniqueness in the custSrcId of the customers

          const customerWithChildAndVendors: ICustomerWithChildAndVendors = {
            recordId: customer.recordId,
            custSrcId: customer.custSrcId!,
            custName: customer.custName!,
            // always have cust src id and cust name since this is from the database already
            isNew: customer.isNew,
            totalArAmount: customer.arAmount,
            childCustomers: [],
            vendors: linkedVendors
          };

          prevValue.push(customerWithChildAndVendors);
        }
      }

      return prevValue;
    }, [] as ICustomerWithChildAndVendors[]);

    // Get the Unlinked Vendors
    const vendorSection = vendors.filter(vendor => !vendor.archive && !vendor.arCustomerId);

    return { customerSection, vendorSection };
  }

  const handleReset = (
    formikHelpers: FormikHelpers<{
      vendorsToEdit: IARVendor[];
    }>
  ) => {
    setCustomersList([]);
    setFilteredCustomersList([]);
    setVendorsList([]);
    setFilteredVendorsList([]);
    setTriggerResetSearch(!triggerResetSearch);
    setSelectedVendors([]);
    formikHelpers.setFieldValue('vendorsToEdit', []);
  }

  const handleSubmit = async (
    values: {
      vendorsToEdit: IARVendor[];
    },
    formikHelpers: FormikHelpers<{
      vendorsToEdit: IARVendor[];
    }>
  ) => {
    try {
      const response = await axiosInstance.request({
        url: arVendorsAPI.BATCH_ENDPOINT,
        method: PUT,
        data: values.vendorsToEdit.map(vendor => ({ ...vendor, isNew: false }))
      });

      if (response.status === 201) {
        const customerIdsToClearNew = values.vendorsToEdit
          .filter(vendor => vendor.arCustomerId)
          .map(vendor => vendor.arCustomerId as number);
        await clearNewCustomersByIds(customerIdsToClearNew);

        setToaster({ open: true, message: 'Changes saved', severity: 'success' });
      } else {
        throw new Error();
      }

      handleReset(formikHelpers);
      setTriggerFetching(!triggerFetching);
    } catch (error) {
      console.log('SUBMITTING FORM ERROR: ', error);
      setToaster({ open: true, message: 'Failed to link customers and vendors!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }

  const handleCancel = async (
    formik: FormikProps<{
      vendorsToEdit: IARVendor[];
    }>
  ) => {
    if (formik.dirty) {
      setShowConfirmCancelModal(true);
    } else {
      navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/customers`)
    }
  }

  // Rendering the component
  if (!canEditParentChildRelationship) {
    return (
      <Box
        data-testid='no-permission-verbiage'
        sx={styles.noPermissionBox}
      >
        You do not have the permissions to view this page.
      </Box>
    );
  }
    
  return (
    <Formik
      enableReinitialize
      validationSchema={editVendorCustomerSchema}
      initialValues={{
        vendorsToEdit: [] as IARVendor[],
      }}
      onSubmit={(values, formikHelpers) => {
        handleSubmit(values, formikHelpers);
      }}
    >
      {formik => {
        return (
          <Form>
            {/* Title, Save, and Cancel Buttons */}
            <Box sx={styles.titleSaveCancelContainer}>
              <Box sx={styles.titleContainer}>
                <Typography
                  tabIndex={0}
                  sx={styles.title}
                >
                  Edit Vendor and Customer Relationship
                </Typography>
              </Box>
              <Box sx={styles.saveCancelContainer}>
                <Button
                  variant='outlined'
                  type='button'
                  disabled={isFetching || formik.isSubmitting}
                  onClick={() => {
                    handleCancel(formik);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={!formik.dirty || formik.isSubmitting}
                >
                  {formik.isSubmitting ? (<CircularProgress size={15} />) : 'Save'}
                </Button>
              </Box>
            </Box>

            {/* Main Components */}
            <Box sx={styles.mainComponentsContainer}>
              <CustomersSection
                {...props}
                formik={formik}
              />
              <VendorsSection
                {...props}
                formik={formik}
              />
            </Box>

            <ConfirmModal
              open={showConfirmCancelModal}
              onButtonClose={() => {
                setShowConfirmCancelModal(false);
              }}
              onClose={() => {
                setShowConfirmCancelModal(false);
                navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/customers`)
              }}
              onConfirm={() => {
                setShowConfirmCancelModal(false);
              }}
              promptChecker
              title='Confirm Navigation'
              description='You have unsaved changes. Are you sure you want to leave this page?'
              yesButtonText='Keep Editing'
              noButtonText='Discard Changes'
              confirmOnly
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditVendorCustomer;