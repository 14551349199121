import { useContext, useEffect, useState } from 'react'
import ComboBox from '../../../components/common/combo-box'
import { IOption } from '../../../interfaces/comboBox';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../service/axiosInstance';
import { arCollateralAPI } from '../../../service/api';
import { GET } from '../../../utility/constants';
import { IARCollateral } from '../../../interfaces';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../context/customerSettingsContext';

export interface CustomerSettingsCollateralDropdownProps {
  resetSearchesAndTabs: () => void;
}

const CustomerSettingsCollateralDropdown = (props: CustomerSettingsCollateralDropdownProps) => {
  const { resetSearchesAndTabs } = props;

  const navigate = useNavigate();
  
  const { selectedClient } = useContext(SelectedClientContext);
  const {
    selectedARCollateral, setSelectedARCollateral,
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  
  const [arCollaterals, setArCollaterals] = useState<IOption[]>([]);
  const [arCollateralInput, setArCollateralInput] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedClient) {
      setArCollaterals([]);
      setArCollateralInput('');
      setSelectedARCollateral(null);
    } else if (selectedClient.parentClient) {
      const allOpt = {
        recordId   : -1,
        label      : 'All', 
        borrowerId : selectedClient.recordId ?? 0,
        default    : true,
      }
      setArCollaterals([allOpt]);
      setArCollateralInput('All');
      setSelectedARCollateral(allOpt);
    } else {
      fetchArCollaterals(selectedClient.recordId);
    }
  }, [selectedClient])

  const fetchArCollaterals = async (borrowerId?: number) => {
    try {
      setIsFetching(true);
      const response = await axiosInstance.request({
        url: arCollateralAPI.FIND_IS_ARCHIVED,
        method: GET,
        params: {
          borrowerId,
          isArchived: false,
          sortBy: 'arCollateralName,ASC'
        }
      });

      setOptionsAndDefault(response.data.content, borrowerId);
    } catch (error) {
      console.log('FETCH AR COLLATERALS ERROR: ', error);
    } finally {
      setIsFetching(false);
    }
  }

  const setOptionsAndDefault = (
    arCollaterals: IARCollateral[],
    borrowerId?: number
  ) => {
    const opts: IOption[] = arCollaterals.map(arCollateral => {
      const opt: IOption = {
        recordId: arCollateral.recordId,
        label: arCollateral.arCollateralName,
        default: arCollateral.default,
      };

      return opt;
    });

    setArCollaterals(opts);

    const defaultOpt = opts.find(opt => opt.default);
    setSelectedARCollateral(defaultOpt ?? null);

    navigate(`/clients/${borrowerId}/settings/${defaultOpt?.recordId}/customers`);
  }

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: IOption | null) => {
    setSelectedARCollateral(newValue);
    resetSearchesAndTabs();

    if (!newValue) {
      // null collateral param will trigger an empty table
      navigate(`/clients/${selectedClient?.recordId}/settings/null/customers`);
    } else {
      // both client and ar collateral ids populated will trigger fetching of customers
      navigate(`/clients/${selectedClient?.recordId}/settings/${newValue.recordId}/customers`);
    }
  }

  const handleInputChange = (_event: React.SyntheticEvent<Element, Event>, newInput: string) => {
    setArCollateralInput(newInput);
  }

  return (
    <ComboBox
      id='arCollateral'
      options={arCollaterals}
      value={selectedARCollateral}
      inputValue={arCollateralInput}
      disabled={selectedClient?.parentClient || isFetching}
      onChange={handleChange}
      onInputChange={handleInputChange}
      isLoading={isFetching}
    />
  )
}

export default CustomerSettingsCollateralDropdown