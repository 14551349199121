import { FC, HTMLAttributes, SyntheticEvent, useCallback } from "react";
import { Autocomplete, AutocompleteRenderInputParams, Box, Chip, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import styles from "./styles";
import { IComboBoxIds, IOption } from "../../../interfaces/comboBox";
import { getOptionLabel, isOptionEqualToValue } from "../../../utility/helper";

interface IComboBoxProps {
  id           : IComboBoxIds;
  options      : IOption[];
  value        : IOption | null;
  inputValue   : string;
  onChange     : (event: SyntheticEvent<Element, Event>, newValue: IOption | null) => void;
  onInputChange: (event: SyntheticEvent<Element, Event>, newInput: string) => void;
  disabled?    : boolean;
  excludeParentClients?: boolean;
  isLoading?   : boolean;
}

const getIdandLabel = (id: IComboBoxIds) => {
  switch (id) {
    case 'collaterals':
      return {id: `${id}-type-dropdown`, label: 'Collateral Type', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'client':
      return {id: `${id}-name-dropdown`, label: 'Client Name', labelStyle: {...styles.labelCommon, ...styles.labelClient}, dropdownStyle: styles.dropdownCommon};
    case 'invCollateral':
      return {id: `${id}-name-dropdown`, label: 'Collateral Name', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'customer':
      return {id: `${id}-name-dropdown`, label: 'Customer Name', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'arCollateral':
      return {id: `${id}-dropdown`, label: 'AR Collateral', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'bbPeriod':
      return {id: `${id}-dropdown`, label: 'As of Date', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'type':
      return {id: `${id}-dropdown`, label: 'Type', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};
    case 'currency':
      return {id: `${id}-dropdown`, label: 'Currency', labelStyle: styles.labelCommon, dropdownStyle: {...styles.dropdownCommon, ...styles.dropdownOthers}};    
    default:
      return {id: '', label: ''};
  }
};

const ComboBox: FC<IComboBoxProps> = (props) => {

  /**
   * This function returns a boolean value depending on the parent status of the option.
   * @param option The name of the option
   * @returns True, if the option is a parent client, otherwise, false.
   */
  const isSelectedFieldNameParentFieldValue = (option: string) => {
    if (props.id === 'client') {
      const parentClients = props.options.filter(client => client.parentClient).map(client => client.label);
      return parentClients.includes(option);
    } else {
      return false;
    }
  };
  
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField 
      {...params} 
      placeholder='Please Select' 
      inputProps={{...params.inputProps, 'data-testid':`combo-box-${props.id}`}}
      InputProps={{
        ...params.InputProps,
        endAdornment:(
          <>
            {props.isLoading ?
            <CircularProgress color='inherit' size={15} sx={styles.parentChipContainer} />
            :
            <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(props.inputValue) && styles.hidden) }}>
              <Chip size='small' sx={styles.parentIdentifierChip} />
            </Box>}
            {params.InputProps.endAdornment}
          </>
          
        )
      }}
      
    />
  );
  const paperComponent = useCallback((props: HTMLAttributes<HTMLElement>) => (<Paper sx={styles.dropdownCommon}>{props.children}</Paper>), [])

  return (
    <>
    <Typography tabIndex={0} component='label' htmlFor={getIdandLabel(props.id).id} sx={getIdandLabel(props.id).labelStyle}>
      {getIdandLabel(props.id).label}
    </Typography>
    <Autocomplete
      id={getIdandLabel(props.id).id}
      data-testid={getIdandLabel(props.id).id}
      disablePortal
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      options={props.options}
      value={props.value}
      inputValue={props.inputValue}
      onChange={props.onChange}
      onInputChange={props.onInputChange}
      renderInput={renderInput}
      PaperComponent={paperComponent}
      size='small'
      aria-label={props.disabled ? `${getIdandLabel(props.id).label} Dropdown dropdown button disabled` : `${getIdandLabel(props.id).label}`}
      tabIndex={props.disabled ? 0 : undefined}
      componentsProps={{
        popupIndicator: { 'aria-label':'Dropdown icon',tabIndex: 0 },
        clearIndicator:{'aria-label':'Clear icon', tabIndex: 0}}
      }
      renderOption={(prop, option) => {
        return (
          <Box component='li' {...prop} sx={{fontSize:'14px'}}>
            <Box tabIndex={0} sx={styles.labelChipContainer}>{option.label}</Box>
            <Box sx={{ ...styles.parentChipContainer, ...(!isSelectedFieldNameParentFieldValue(option.label) && styles.hidden) }}>
              <Chip size='small' sx={styles.parentIdentifierChip} />
            </Box>
          </Box>
        );
      }}
      disabled={props.disabled}
      sx={getIdandLabel(props.id).dropdownStyle}
    />
    </>
  )
}
export default ComboBox;