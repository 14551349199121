const styles = {
  footerContainer: {
    backgroundColor: 'white',
    color: '#5D6A87',
    fontSize: '11px',
    width: '100%',
    boxShadow: 'inset 0px 4px 3px -3px rgba(50, 50, 50, 0.75);',
    paddingY: '0.3rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&.MuiBox-root': {
      height: '25px',
    }
  },
  footerVersion: {
    paddingLeft: 9 
  },
  supportContainer: { 
    paddingRight: '0.5rem',
    fontSize: '12px !important'
  }
}

export default styles;