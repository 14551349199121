import colors from "../../utility/colors";

const styles = {
  breadCrumbBox: {
    paddingRight: '24px',
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.BACKGROUND_COLOR,
    width: '100%',
    height: '100%',
  },
  customerSettingsHeader: {
    backgroundColor: '#fff',
    marginY: '1rem',
    paddingX: '1rem',
    paddingY: '2rem',
  },
  title: {
    fontWeight: 'bold',
    color: '#010953',
    fontSize: '18px',
  },
  titleContainer: {
    backgroundColor: '#FEFEFE',
    py: 2.5
  },
  clientDropdown: {
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  navButtonBase: {
    height: '36.6px',
    borderColor: '#E0E0E0',
    color: '#212529'
  },
  selectedBase: {
    height: '36.6px',
    borderColor: '#154A8A',
    color: '#154A8A'
  },
  rightBorder: {
    borderRadius: '0 4px 4px 0', 
  },
  leftBorder: {
    borderRadius: '4px 0 0 4px', 
  },
  customerListText: {
    fontSize: '16px', 
    color: '#154A8A',
  },
  buttonText: {
    fontSize: '14px',
    color: '#fff',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  hidden: {
    display: 'none'
  },
  MarginLeft: {
    marginLeft: '24px'
  },
};

export default styles;
