import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import styles from './styles';
import _ from 'lodash';

export interface CustomerSettingsSearchBarProps {
  filters: string[];
  onSearch?: (value: string) => void;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
}

const CustomerSettingsSearchBar = (props: CustomerSettingsSearchBarProps) => {
  const {
    filters,
    onSearch,
    selectedValue,
    setSelectedValue,
    searchValue,
    setSearchValue,
    disabled
  } = props;

  const handleChangeFilter = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);

    let debounce = _.debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onSearch && onSearch(event.target.value.trim().toLowerCase());
    }, 1500);

    debounce(event);
  }

  return (
    <Box sx={styles.searchAndFilterContainer}>
      <Select
        id='filter-dropdown'
        value={selectedValue}
        onChange={handleChangeFilter}
        sx={styles.filterStyle}
        aria-labelledby='filter-dropdown'
        inputProps={{
          'aria-label': 'Filter Dropdown',
          'aria-labelledby': 'filter-dropdown'
        }}
        disabled={disabled}
      >
        {filters.map(filter =>
          <MenuItem key={filter} value={filter}>
            {filter}
          </MenuItem>
        )}
      </Select>
      <TextField
        inputProps={{ 'aria-label': 'Search-Textfield' }}
        id='search-text-field'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' tabIndex={0} aria-label='Search icon'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchValue}
        onChange={handleSearch}
        placeholder='Search'
        size='small'
        sx={styles.searchField}
        disabled={disabled}
      />
    </Box>
  )
}

export default CustomerSettingsSearchBar