import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CustomerSettingsMainViewContext, ICustomerSettingsMainViewContext } from '../../../../../context/customerSettingsMainViewContext';
import { Box, Checkbox, Chip, CircularProgress, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import styles from './styles';
import NewTag from '../../../../../components/common/new-tag';
import { IARCustomer, IARVendor } from '../../../../../interfaces';
import { formatDate, getUniqueKeys } from '../../../../../utility/helper';
import { CustomerVendorTableProps } from '..';
import { CustomerSettingsContext, ICustomerSettingsContext } from '../../../../../context/customerSettingsContext';
import { AMERICAN_DATE_FORMAT, GET, PUT } from '../../../../../utility/constants';
import { DeleteOutlined, HistoryOutlined, Inventory2Outlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';
import { SelectedClientContext } from '../../../../../context/selectedClientContext';
import axiosInstance from '../../../../../service/axiosInstance';
import { arCustomersAPI, arVendorsAPI } from '../../../../../service/api';
import CustomerVendorModal from '../../../customer-vendor-modal';
import customerSchema from '../../../../../schemas/customerSchema';
import vendorSchema from '../../../../../schemas/vendorSchema';
import { FormikHelpers } from 'formik';
import ChildrenVendorRow from './children-vendor-row';

export interface CustomerVendorRowProps extends CustomerVendorTableProps {
  customerVendor: IARCustomer | IARVendor;
}

const CustomerVendorRow = (props: CustomerVendorRowProps) => {
  const {
    customerVendor,
    archive,
    type,
    viewByParent,
    viewByCustomer
  } = props;

  const { selectedClient } = useContext(SelectedClientContext);

  const {
    canViewCustomerInformation,
    setToaster,
    selectedARCollateral
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const {
    actionPanel, setActionPanel,
    selectAll, setSelectAll,
    customerVendorList, setCustomerVendorList,
    customerVendorCount, 
    setActionModal,
    triggerSearch, setTriggerSearch,
    triggerSelectAll,
  } = useContext(CustomerSettingsMainViewContext) as ICustomerSettingsMainViewContext;

  const [open, setOpen] = useState<boolean>(false);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullListForModal, setFullListForModal] = useState<IARCustomer[] | IARVendor[]>([]); // needed to prevent white screen error
  const [selected, setSelected] = useState<boolean>(false);
  const [openChildVendorRows, setOpenChildVendorRows] = useState<boolean>(false);
  const [isFetchingChildVendorRows, setIsFetchingChildVendorRows] = useState<boolean>(false);
  const [childrenAndVendors, setChildrenAndVendors] = useState<IARCustomer[] | IARVendor[]>([]);

  const updatedCustomerList = useMemo(() => {
    const removedSelf = (fullListForModal as IARCustomer[]).filter((value: IARCustomer) => value.recordId !== customerVendor.recordId);
    return removedSelf;
  }, [fullListForModal, customerVendor])

  const updatedVendorList = useMemo(() => {
    const removedSelf = (fullListForModal as IARVendor[]).filter((value: IARVendor) => value.recordId !== customerVendor.recordId);
    return removedSelf;
  }, [fullListForModal, customerVendor])

  const rowCells = useMemo(() => 
    props.headers.map((header) => {
      if (header.type === 'date') {
        return customerVendor[header.id] ? formatDate(customerVendor[header.id], AMERICAN_DATE_FORMAT) : '-';
      } else {
        return customerVendor[header.id] ?? '-';
      }
    })
  , [customerVendor])

  const tableLabel = useMemo(() => 
    type === 'customer' ? 'Customer' : 'Vendor'
  , [type])

  const noParents = useMemo(() => customerVendor.recordId === 0, [customerVendor]);
  
  useEffect(() => {
    const allChecked = selectAll || [...customerVendorList].every(item => item.selected);
    setSelected(allChecked);
  }, [triggerSelectAll])

  const handleOpenModal = async () => {
    try {
      setIsLoading(true);

      const list = type === 'customer' ?
        await fetchCustomers(selectedClient?.recordId, selectedARCollateral?.recordId) :
        await fetchVendors(selectedClient?.recordId, selectedARCollateral?.recordId);
        
      setFullListForModal(list);
      setOpen(true);
    } catch (error) {
      console.log('ERROR FETCHING FULL LIST: ', error);
      setToaster({ open: true, message: 'Failed to fetch list!', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpenRow = async () => {
    if (openChildVendorRows) {
      setOpenChildVendorRows(false);
    } else if (childrenAndVendors.length) {
      setOpenChildVendorRows(true);
    } else if (viewByParent) {
      await fetchChildren();
    } else {
      await fetchChildrenAndVendors();
    }
  }

  const fetchChildren = async () => {
    try {
      setIsFetchingChildVendorRows(true);

      if (noParents) {
        const orphanResponse = await axiosInstance.request({
          url: arCustomersAPI.FIND_ORPHANS,
          method: GET,
          params: {
            borrowerId: selectedClient?.recordId,
            arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
          }
        });

        setChildrenAndVendors(orphanResponse.data);
      } else {
        const custResponse = await axiosInstance.request({
          url: arCustomersAPI.FIND_BY_PARENT_ID,
          method: GET,
          params: {
            arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
            parentId: customerVendor.recordId,
          }
        });
  
        setChildrenAndVendors(custResponse.data);
      }
      
      setOpenChildVendorRows(true);
    } catch (error) {
      console.log('ERROR FETCHING CHILDREN: ', error);
      setToaster({ open: true, message: 'Failed to fetch children!', severity: 'error' });
    } finally {
      setIsFetchingChildVendorRows(false);
    }
  }

  const fetchChildrenAndVendors = async () => {
    try {
      setIsFetchingChildVendorRows(true);

      if (noParents) {
        const unlinkedResponse = await axiosInstance.request({
          url: selectedClient?.parentClient ?
            arVendorsAPI.FIND_UNLINKED_BY_PARENT_BORROWER_ID_NO_PAGINATION : 
            arVendorsAPI.FIND_UNLINKED_BY_AR_COLLATERAL_ID_NO_PAGINATION,
          method: GET,
          params: {
            borrowerId: selectedClient?.recordId,
            arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
          }
        });

        setChildrenAndVendors(unlinkedResponse.data);
      } else {
        const custResponse = await axiosInstance.request({
          url: arCustomersAPI.FIND_BY_PARENT_ID,
          method: GET,
          params: {
            arCollateralId: selectedClient?.parentClient ? undefined : selectedARCollateral?.recordId,
            parentId: customerVendor.recordId,
          }
        });

        const vendorResponse = await axiosInstance.request({
          url: arVendorsAPI.FIND_BY_AR_CUSTOMER_ID,
          method: GET,
          params: {
            arCustomerId: customerVendor.recordId,
          }
        });
  
        setChildrenAndVendors([...custResponse.data, ...vendorResponse.data]);
      }
      
      setOpenChildVendorRows(true);
    } catch (error) {
      console.log('ERROR FETCHING CHILDREN AND VENDORS: ', error);
      setToaster({ open: true, message: 'Failed to fetch children!', severity: 'error' });
    } finally {
      setIsFetchingChildVendorRows(false);
    }
  }
  
  const fetchCustomers = async (borrowerId?: number, arCollateralId?: number) => {
    if (selectedClient?.parentClient) {
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const customers: IARCustomer[] = custResponse.data.content;
      return customers;
    } else {
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const customers: IARCustomer[] = custResponse.data.content;
      return customers;
    }
  }

  const fetchVendors = async (borrowerId?: number, arCollateralId?: number) => {
    if (selectedClient?.parentClient) {
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      return vendors;
    } else {
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      return vendors;
    }
  }

  const getCustName = (custName: string, parentTag?: React.ReactNode, count?: React.ReactNode) => {
    if (custName.length >= 30) {
      return (
        <Tooltip title={`${custName}`}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={styles.custName}>{custName}</Typography>
            {count}
            {parentTag}
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <Typography sx={styles.custName}>{custName}</Typography>
          {count}
          {parentTag}
        </Box>
      );
    }
  }

  const checkIfParentCustomer = (data: IARCustomer | IARVendor) => {
    if ('isUpcParent' in data || 'isCustParent' in data) {
      const custInfo = data;
      return custInfo.isCustParent || custInfo.isUpcParent;
    }

    return false;
  }

  const handleClose = () => {
    setOpen(false);
    setIsEditting(false);
  }

  const handleSaveCustomer = async (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    try {
      await axiosInstance.request({
        url: `${arCustomersAPI.MAIN_ENDPOINT}/${values.recordId}`,
        method: PUT,
        data: { ...values, isNew: false }
      });
      setToaster({ open: true, message: 'Changes saved', severity: 'success' });
    } catch (error) {
      console.log('SAVING ERROR: ', error);
      setToaster({ open: true, message: 'Error in saving customer!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
      handleClose();
      setTriggerSearch(!triggerSearch);
    }
  }

  const handleSaveVendor = async (values: IARCustomer | IARVendor, formikHelpers: FormikHelpers<IARCustomer | IARVendor>) => {
    try {
      await axiosInstance.request({
        url: `${arVendorsAPI.MAIN_ENDPOINT}/${values.recordId}`,
        method: PUT,
        data: { ...values, isNew: false }
      });
      setToaster({ open: true, message: 'Changes saved', severity: 'success' });
    } catch (error) {
      console.log('SAVING ERROR: ', error);
      setToaster({ open: true, message: 'Error in saving vendor!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
      handleClose();
      setTriggerSearch(!triggerSearch);
    }
  }

  const renderDeleteArchive = (canDelete: number, archive: boolean) => {
    if (archive) {
      return (
        <Tooltip title={actionPanel.open ? `Restore action is disabled when selecting ${type}/s` : `Restore ${tableLabel}`}>
          <span>
          <IconButton
            disabled={actionPanel.open}
            aria-label={`Restore ${tableLabel}`} 
            data-testid={`restore-${customerVendor.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [customerVendor.recordId!],
                dataType: type,
                actionType: 'restore',
                open: true,
                title: `Restore ${tableLabel}`,
                description: `You are about to restore this ${type}. Are you sure?`,
                yesButtonText: 'Restore',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <HistoryOutlined />
          </IconButton>
          </span>
        </Tooltip>
      )
    }

    if (noParents) {
      return null;
    }

    return (
      <>
      {isLoading ? 
        <Box sx={styles.isLoading}>
          <CircularProgress size={15} />
        </Box>
        :
        <Tooltip title={actionPanel.open ? `View action is disabled when selecting ${type}/s` : `View ${tableLabel} Details`}>
          <IconButton
            disabled={actionPanel.open}
            aria-label={`View ${tableLabel} details icon`}
            color='primary'
            size='small'
            onClick={handleOpenModal}
          >
            <PreviewIcon />
          </IconButton>
        </Tooltip>}
      {!(viewByParent || viewByCustomer) && getDeleteArchiveIcon(canDelete)}
      </>
    )
  }

  const getDeleteArchiveIcon = (canDelete: number) => {
    if (canDelete === 1) {
      return (
        <Tooltip title={actionPanel.open ? `Delete action is disabled when selecting ${type}/s` : `Delete ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open || isLoading}
            aria-label={`Delete ${tableLabel}`}
            color='primary'
            size='small'
            data-testid={`delete-${customerVendor.recordId}`}
            onClick={() => {
              setActionModal({
                recordIds: [customerVendor.recordId!],
                dataType: type,
                actionType: 'delete',
                open: true,
                title: `Delete ${tableLabel}`,
                description: `You are about to delete this ${type}. Are you sure?`,
                yesButtonText: 'Delete',
                noButtonText: 'Cancel',
                errorButton: true,
              });
            }}
          >
            <DeleteOutlined />
          </IconButton>
          </span>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title={actionPanel.open ? `Archive action is disabled when selecting ${type}/s` : `Archive ${tableLabel}`}>
          <span>
          <IconButton 
            disabled={actionPanel.open|| isLoading}
            aria-label={`Archive ${tableLabel}`} 
            data-testid={`archive-${customerVendor.recordId}`}
            color='primary'
            size='small' 
            onClick={() => {
              setActionModal({
                recordIds: [customerVendor.recordId!],
                dataType: type,
                actionType: 'archive',
                open: true,
                title: `Archive ${tableLabel}`,
                description: `You are about to archive this ${type}. Are you sure?`,
                yesButtonText: 'Archive',
                noButtonText: 'Cancel',
                errorButton: false,
              });
            }}
          >
            <Inventory2Outlined />
          </IconButton>
          </span>
        </Tooltip>
      )
    }
  }

  const getIcon = () => {
    if (viewByParent || viewByCustomer) {
      if (openChildVendorRows) {
        return <KeyboardArrowUp sx={styles.arrow} />
      } else if (isFetchingChildVendorRows) {
        return <CircularProgress size={15} sx={styles.circularProgress}/>
      } else {
        return <KeyboardArrowDown sx={styles.arrow} />
      }
    } else {
      return (
        <Checkbox 
          data-testid={`select-${customerVendor.recordId}`}
          checked={selected}
          onClick={() => {
            setSelected(!selected);

            const newDataList = [
              ...customerVendorList
            ];

            const findIndex = newDataList.findIndex((data) => data.recordId === customerVendor.recordId);

            newDataList[findIndex].selected = !newDataList[findIndex].selected;
            setCustomerVendorList(newDataList);

            const selectedList = newDataList
              .filter((data) => data.selected);

            setActionPanel({
              restore: Boolean(archive),
              open: selectedList.length > 0,
            });

            if (selectAll && selected) {
              // remove select all state when an item is unselected
              setSelectAll(false);
            }
          }}
        />
      )
    }
  }

  const getChildVendorRows = () => {
    return (
      <>
      <TableRow> 
        <TableCell sx={styles.tableHead} />
        <TableCell sx={styles.tableHeadSelectTag} />
        <TableCell
          width={viewByParent ? '42.5%' : '28%'}
          sx={styles.tableHead}
        >  
          {viewByParent ? 'Customer Name' : 'Accounts Name'}
        </TableCell>
        <TableCell
          width={viewByParent ? '42.5%' : '28%'}
          sx={styles.tableHead}
        >  
          {viewByParent ? 'Customer ID' : 'Accounts ID'}
        </TableCell>
        {viewByCustomer &&
        <TableCell
          sx={styles.tableHead}
        >  
          {'Date Created'}
        </TableCell>}
        <TableCell width={'10%'} sx={styles.tableHead}>
          {!canViewCustomerInformation ? 
            null :
            <Box
              tabIndex={0}
              sx={{
                textAlign: 'center',
                marginLeft: '0.8rem',
              }}
            >
              Action
            </Box>
          }
        </TableCell>
      </TableRow>
      {childrenAndVendors.map((childVendor: IARCustomer | IARVendor) => (
        <ChildrenVendorRow
          {...props}
          key={childVendor.recordId}
          childVendor={childVendor}
          getCustName={getCustName}
          fetchCustomers={fetchCustomers}
          fetchVendors={fetchVendors}
          handleSaveCustomer={handleSaveCustomer}
          handleSaveVendor={handleSaveVendor}
        />
      ))}
      </>
    )
  }

  return (
    <>
    <TableRow sx={styles.tableRow}>
      <TableCell sx={styles.tableCellNewTag}>
        <Box sx={styles.newTagBox}>
          { customerVendor.isNew && <Typography><NewTag expanding /></Typography> }
        </Box>
      </TableCell>
      <TableCell
        onClick={(viewByParent || viewByCustomer) ? handleOpenRow : undefined}
        sx={styles.tableCellNewTag}
      >
        <Box sx={styles.iconBox}>
          {getIcon()}
        </Box>
      </TableCell>
      {rowCells.map((rowHeader, index) =>
        <TableCell 
          width={'20%'} 
          tabIndex={0} 
          key={getUniqueKeys(rowHeader)} 
          onClick={(viewByParent || viewByCustomer) ? handleOpenRow : handleOpenModal} 
          sx={styles.tableCell}
        >
          {getCustName(
            rowHeader,
            (index === 0 && !(viewByParent) &&
              checkIfParentCustomer(customerVendor) &&
              <Chip size='small' sx={styles.parentIdentifierChip} />
            ),
            (index === 0 && (viewByParent || viewByCustomer) &&
              <Typography
                tabIndex={0}
                sx={styles.count}
              >
                {`(${customerVendorCount[customerVendor.recordId ?? 0] ?? 0})`}
              </Typography>
            )
          )}
        </TableCell>
      )}
      {
        canViewCustomerInformation && 
        <TableCell
          sx={{
            ...styles.tableCell,
            ...styles.tableCellAction,
          }}
          onClick={(viewByParent || viewByCustomer) && noParents ? handleOpenRow : undefined}
        >
          { renderDeleteArchive(customerVendor.canDelete!, Boolean(archive)) }
        </TableCell>
      }
    </TableRow>

    {openChildVendorRows && getChildVendorRows()}
    {type === 'customer' ?
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={customerVendor}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveCustomer}
        onClose={handleClose}
        schema={customerSchema(updatedCustomerList, false, selectedClient?.parentClient)}
      />
      :
      <CustomerVendorModal
        open={open}
        isEditting={isEditting}
        selectedCustomerVendor={customerVendor}
        onEdit={() => setIsEditting(true)}
        onSave={handleSaveVendor}
        onClose={handleClose}
        schema={vendorSchema(updatedVendorList)}
      />
    }
    </>
  )
}

export default CustomerVendorRow