import { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";
import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import styles from "./styles";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { EditParentChildContext, IEditParentChildContext } from "../../../context/editParentChildContext";
import editParentChildSchema from "../../../schemas/editParentChildSchema";
import ParentsSection from "./parents-section";
import OrphansSection from "./orphans-section";
import { IARCustomer, IARVendor, IClient } from "../../../interfaces";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import axiosInstance from "../../../service/axiosInstance";
import { GET, POST } from "../../../utility/constants";
import { arCustomersAPI, arVendorsAPI } from "../../../service/api";
import { IParentWithChild } from "../../../interfaces/editParentChildInterface";
import ConfirmModal from "../../../components/modals/confirm-modal";
import { useNavigate } from "react-router-dom";

export interface EditParentChildProps {

}

// Interface
export interface MainSectionProps extends EditParentChildProps {
  formik: FormikProps<{
    customersToEdit: IARCustomer[];
    parentCustomersToSave: IARCustomer[];
    parentCustomersToDelete: IARCustomer[];
  }>;
  tableContainerRef: MutableRefObject<HTMLDivElement | null>;
  isUltimateParent: boolean;
}

// Functions
export const getCustNameAndCustSrcId = (customer: IARCustomer) => {
  const custName = customer.custName as string;
  const custSrcId = customer.custSrcId as string;

  if (custName.length >= 37) {
    return (
      <Tooltip
        title={custName}
        placement='bottom-start'
      >
        <Box>
          <Typography
            tabIndex={0}
            id={custSrcId}
            sx={styles.custName}
          >
            {custName}
          </Typography>
          <Typography
            tabIndex={0}
            sx={styles.custSrcId}
          >
            {`Customer ID: ${custSrcId}`}
          </Typography>
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <>
      <Typography
        tabIndex={0}
        id={custSrcId}
        sx={styles.custName}
      >
        {custName}
      </Typography>
      <Typography
        tabIndex={0}
        sx={styles.custSrcId}
      >
        {`Customer ID: ${custSrcId}`}
      </Typography>
      </>
    )
  }
}

const EditParentChild = (props: EditParentChildProps) => {
  const navigate = useNavigate();

  // Contexts
  const {
    canEditParentChildRelationship,
    setToaster,
    selectedARCollateral
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const { selectedClient } = useContext(SelectedClientContext);

  const {
    setInitialCustomers,
    setInitialVendors,
    isFetching, setIsFetching,
    setParentsList,
    setFilteredParentsList,
    setOrphansList,
    setFilteredOrphansList,
    triggerResetSearch, setTriggerResetSearch,
    setSelectedCustomers,
  } = useContext(EditParentChildContext) as IEditParentChildContext;

  // Refs
  const parentTableRef = useRef<HTMLDivElement | null>(null);
  const orphanTableRef = useRef<HTMLDivElement | null>(null);

  // States
  const [triggerFetching, setTriggerFetching] = useState<boolean>(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState<boolean>(false);

  // Memo
  const isUltimateParent = useMemo(() => Boolean(selectedClient?.parentClient), [selectedClient])

  // Effects
  useEffect(() => {
    // Fetching initial data
    if (selectedClient === null) return;

    fetchCustomers(selectedClient);
  }, [selectedClient, selectedARCollateral, triggerFetching]);

  // Functions
  const fetchCustomers = async (selectedClient: IClient) => {
    setIsFetching(true);
    if (isUltimateParent) {
      await fetchUpcCustomers(selectedClient.recordId as number);
    } else if (selectedARCollateral) {
      await fetchArCustomers(selectedARCollateral.recordId);
    }
    setIsFetching(false);
  }

  const fetchUpcCustomers = async (borrowerId: number) => {
    try {
      // Fetching Customers
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: {
          borrowerId,
          sortBy: 'custName,ASC'
        }
      });
      const customers: IARCustomer[] = custResponse.data.content;

      // Fetching Ar Amounts
      const arAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_PARENT_BORROWER_ID,
        method: GET,
        params: { parentBorrowerId: borrowerId }
      });
      const arAmountMap = arAmountResponse.data;

      // Fetching Vendors
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_BORROWER_ID,
        method: GET,
        params: { borrowerId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      setInitialVendors(vendors);
      const customerIdsWithVendors: number[] = vendors
        .filter(vendor => Boolean(vendor.arCustomerId))
        .map(vendor => vendor.arCustomerId as number);

      const customersWithAmounts = customers
        .map(customer => {
          const recordId = customer.recordId as number;
          customer.arAmount = arAmountMap[recordId];
          customer.hasVendorsLinked = customerIdsWithVendors.includes(recordId);
          return customer;
        });
      setInitialCustomers(customersWithAmounts);

      // Reducing the array
      const reducedArray = getUpcParentsChildrenAndOrphans(customersWithAmounts, customerIdsWithVendors);
      setParentsList(reducedArray.parentsWithChildren);
      setFilteredParentsList(reducedArray.parentsWithChildren);
      setOrphansList(reducedArray.orphanCustomers);
      setFilteredOrphansList(reducedArray.orphanCustomers);
    } catch (error) {
      console.log('FETCH UPC CUSTOMERS ERROR: ', error);
    }
  }

  const getUpcParentsChildrenAndOrphans = (customers: IARCustomer[], customerIdsWithVendors: number[]) => {
    return customers.reduce((prevValue, customer) => {
      const { parentsWithChildren, orphanCustomers } = prevValue;
      
      if (!customer.archive) {
        // Getting parent and children
        if (customer.upcParentCustSrcId) {
          // Check if parent is existing in the current parentsWithChildren
          const existingParent: IParentWithChild | undefined = parentsWithChildren
            .find(existing => existing.custSrcId === customer.upcParentCustSrcId);

          if (existingParent) {
            // Add to the customer array of the parent
            existingParent.childCustomers.push(customer);
          } else {
            // Create a new parent with child
            const parentWithChild: IParentWithChild = {
              recordId: customer.upcParentCustId,
              custSrcId: customer.upcParentCustSrcId,
              custName: customer.upcParentCustName!,
              // always have upc parent cust id since this is from the database already
              hasVendorsLinked: customerIdsWithVendors.includes(customer.upcParentCustId!),
              totalArAmount: 0.0, // initial amount
              childCustomers: [ customer ]
            };

            parentsWithChildren.push(parentWithChild);
          }
        }

        // Getting orphans 
        if (!customer.upcParentCustSrcId && !customer.isUpcParent) {
          orphanCustomers.push(customer);
        }
      }

      return prevValue;
    }, {
      parentsWithChildren: [] as IParentWithChild[],
      orphanCustomers: [] as IARCustomer[]
    })
  }

  const fetchArCustomers = async (arCollateralId: number) => {
    try {
      // Fetching Customers
      const custResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: {
          arCollateralId,
          sortBy: 'custName,ASC'
        }
      });
      const customers: IARCustomer[] = custResponse.data.content;

      // Fetching Ar Amounts
      const arAmountResponse = await axiosInstance.request({
        url: arCustomersAPI.FIND_AR_AMOUNT_MAP_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const arAmountMap = arAmountResponse.data;

      // Fetching Vendors
      const vendorResponse = await axiosInstance.request({
        url: arVendorsAPI.FIND_ALL_BY_AR_COLLATERAL_ID,
        method: GET,
        params: { arCollateralId }
      });
      const vendors: IARVendor[] = vendorResponse.data.content;
      setInitialVendors(vendors);
      const customerIdsWithVendors: number[] = vendors
        .filter(vendor => Boolean(vendor.arCustomerId))
        .map(vendor => vendor.arCustomerId as number);

      const customersWithAmounts = customers
        .map(customer => {
          const recordId = customer.recordId as number;
          customer.arAmount = arAmountMap[recordId];
          customer.hasVendorsLinked = customerIdsWithVendors.includes(recordId);
          return customer;
        });
      setInitialCustomers(customersWithAmounts);

      // Reducing the array
      const reducedArray = getArParentsChildrenAndOrphans(customersWithAmounts, customerIdsWithVendors);
      setParentsList(reducedArray.parentsWithChildren);
      setFilteredParentsList(reducedArray.parentsWithChildren);
      setOrphansList(reducedArray.orphanCustomers);
      setFilteredOrphansList(reducedArray.orphanCustomers);
    } catch (error) {
      console.log('FETCH AR CUSTOMERS ERROR: ', error);
    }
  }

  const getArParentsChildrenAndOrphans = (customers: IARCustomer[], customerIdsWithVendors: number[]) => {
    return customers
    .reduce((prevValue, customer) => {
      const { parentsWithChildren, orphanCustomers } = prevValue;
      // Do not process archived customers
      if (!customer.archive) {
        // Getting parent and children
        if (customer.parentCustSrcId) {
          // Check if parent is existing in the current parentsWithChildren
          const existingParent: IParentWithChild | undefined = parentsWithChildren
            .find(existing => existing.custSrcId === customer.parentCustSrcId);

          if (existingParent) {
            // Add to the customer array of the parent
            existingParent.childCustomers.push(customer);
          } else {
            // Create a new parent with child
            const parentWithChild: IParentWithChild = {
              recordId: customer.parentARCustomerId,
              custSrcId: customer.parentCustSrcId,
              custName: customer.parentCustName!,
              // always have upc parent cust id since this is from the database already
              hasVendorsLinked: customerIdsWithVendors.includes(customer.parentARCustomerId!),
              totalArAmount: 0.0, // initial amount
              childCustomers: [ customer ]
            };

            parentsWithChildren.push(parentWithChild);
          }
        }

        // Getting orphans 
        if (!customer.parentCustSrcId && !customer.isCustParent) {
          orphanCustomers.push(customer);
        }
      }

      return prevValue;
    }, {
      parentsWithChildren: [] as IParentWithChild[],
      orphanCustomers: [] as IARCustomer[]
    })
  }

  const handleReset = (
    formikHelpers: FormikHelpers<{
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }>
  ) => {
    setParentsList([]);
    setFilteredParentsList([]);
    setOrphansList([]);
    setFilteredOrphansList([]);
    setTriggerResetSearch(!triggerResetSearch);
    setSelectedCustomers([]);
    formikHelpers.setFieldValue('customersToEdit', []);
    formikHelpers.setFieldValue('parentCustomersToSave', []);
    formikHelpers.setFieldValue('parentCustomersToDelete', []);
  }

  const handleSubmit = async (
    values: {
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    },
    formikHelpers: FormikHelpers<{
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }>
  ) => {
    try {
      const response = isUltimateParent ?
        await handleSubmitUpcCustomers(values) :
        await handleSubmitArCustomers(values);

      if (response.status === 201) {
        setToaster({ open: true, message: 'Changes saved', severity: 'success' });
      } else {
        throw new Error();
      }

      handleReset(formikHelpers);
      setTriggerFetching(!triggerFetching);
    } catch (error) {
      console.log('SUBMITTING FORM ERROR: ', error);
      setToaster({ open: true, message: 'Failed to link parent and child customers!', severity: 'error' });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }

  const handleSubmitUpcCustomers = async (
    values: {
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }
  ) => {
    return await axiosInstance.request({
      url: arCustomersAPI.LINK_UPC_PARENT_AND_CHILD,
      method: POST,
      data: {
        borrowerId: selectedClient?.recordId,
        ...values
      }
    })
  }

  const handleSubmitArCustomers = async (
    values: {
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }
  ) => {
    return await axiosInstance.request({
      url: arCustomersAPI.LINK_AR_PARENT_AND_CHILD,
      method: POST,
      data: {
        borrowerId: selectedClient?.recordId,
        arCollateralId: selectedARCollateral?.recordId,
        ...values
      }
    })
  }

  const handleCancel = async (
    formik: FormikProps<{
      customersToEdit: IARCustomer[];
      parentCustomersToSave: IARCustomer[];
      parentCustomersToDelete: IARCustomer[];
    }>
  ) => {
    if (formik.dirty) {
      setShowConfirmCancelModal(true);
    } else {
      navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/customers`)
    }
  }

  // Rendering the component
  if (!canEditParentChildRelationship) {
    return (
      <Box
        data-testid='no-permission-verbiage'
        sx={styles.noPermissionBox}
      >
        You do not have the permissions to view this page.
      </Box>
    )
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={editParentChildSchema}
      initialValues={{
        customersToEdit: [] as IARCustomer[],
        parentCustomersToSave: [] as IARCustomer[],
        parentCustomersToDelete: [] as IARCustomer[],
      }}
      onSubmit={(values, formikHelpers) => {
        handleSubmit(values, formikHelpers);
      }}
    >
      {formik => {
        return (
          <Form>
            {/* Title, Save, and Cancel Buttons */}
            <Box sx={styles.titleSaveCancelContainer}>
              <Box sx={styles.titleContainer}>
                <Typography
                  tabIndex={0}
                  sx={styles.title}
                >
                  Edit Parent Child Relationship
                </Typography>
              </Box>
              <Box sx={styles.saveCancelContainer}>
                <Button
                  variant='outlined'
                  type='button'
                  disabled={isFetching || formik.isSubmitting}
                  onClick={() => {
                    handleCancel(formik);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={!formik.dirty || formik.isSubmitting}
                >
                  {formik.isSubmitting ? (<CircularProgress size={15} />) : 'Save'}
                </Button>
              </Box>
            </Box>

            {/* Main Components */}
            <Box sx={styles.mainComponentsContainer}>
              <ParentsSection
                formik={formik}
                tableContainerRef={parentTableRef}
                isUltimateParent={isUltimateParent}
                {...props}
              />
              <OrphansSection
                formik={formik}
                tableContainerRef={orphanTableRef}
                isUltimateParent={isUltimateParent}
                {...props}
              />
            </Box>

            <ConfirmModal
              open={showConfirmCancelModal}
              onButtonClose={() => {
                setShowConfirmCancelModal(false);
              }}
              onClose={() => {
                setShowConfirmCancelModal(false);
                navigate(`/clients/${selectedClient?.recordId}/settings/${selectedARCollateral?.recordId ?? -1}/customers`)
              }}
              onConfirm={() => {
                setShowConfirmCancelModal(false);
              }}
              promptChecker
              title='Confirm Navigation'
              description='You have unsaved changes. Are you sure you want to leave this page?'
              yesButtonText='Keep Editing'
              noButtonText='Discard Changes'
              confirmOnly
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditParentChild;