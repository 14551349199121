import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { IARVendor } from "../interfaces";
import { ICustomerWithChildAndVendors, ISelectedCustomerWithChildAndVendor } from "../interfaces/editVendorCustomerInterface";

export type DroppedInCustomer =  ICustomerWithChildAndVendors | null;

export interface IEditVendorCustomerContext {
  initialVendors: IARVendor[];
  setInitialVendors: Dispatch<SetStateAction<IARVendor[]>>;
  customersList: ICustomerWithChildAndVendors[];
  setCustomersList: Dispatch<SetStateAction<ICustomerWithChildAndVendors[]>>;
  filteredCustomersList: ICustomerWithChildAndVendors[];
  setFilteredCustomersList: Dispatch<SetStateAction<ICustomerWithChildAndVendors[]>>;
  vendorsList: IARVendor[];
  setVendorsList: Dispatch<SetStateAction<IARVendor[]>>;
  filteredVendorsList: IARVendor[];
  setFilteredVendorsList: Dispatch<SetStateAction<IARVendor[]>>;
  isFetching: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  selectedCustomerWithChildAndVendor: ISelectedCustomerWithChildAndVendor | null;
  setSelectedCustomerWithChildAndVendor: Dispatch<SetStateAction<ISelectedCustomerWithChildAndVendor | null>>;
  vendorRemoveConfirmModalOpen: boolean;
  setVendorRemoveConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  droppedInCustomer: DroppedInCustomer;
  setDroppedInCustomer: Dispatch<SetStateAction<DroppedInCustomer>>;
  triggerResetSearch: boolean;
  setTriggerResetSearch: Dispatch<SetStateAction<boolean>>;
  triggerResetSelection: boolean;
  setTriggerResetSelection: Dispatch<SetStateAction<boolean>>;
  selectedVendors: IARVendor[];
  setSelectedVendors: Dispatch<SetStateAction<IARVendor[]>>;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
}

export const EditVendorCustomerContext = createContext<IEditVendorCustomerContext | null>(null);

const EditVendorCustomerProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;

  // For checking initial values
  const [initialVendors, setInitialVendors] = useState<IARVendor[]>([]);

  // For common props
  const [customersList, setCustomersList] = useState<ICustomerWithChildAndVendors[]>([]);
  const [filteredCustomersList, setFilteredCustomersList] = useState<ICustomerWithChildAndVendors[]>([]);
  const [vendorsList, setVendorsList] = useState<IARVendor[]>([]);
  const [filteredVendorsList, setFilteredVendorsList] = useState<IARVendor[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [selectedCustomerWithChildAndVendor, setSelectedCustomerWithChildAndVendor] = useState<ISelectedCustomerWithChildAndVendor | null>(null);
  const [vendorRemoveConfirmModalOpen, setVendorRemoveConfirmModalOpen] = useState<boolean>(false);
  const [droppedInCustomer, setDroppedInCustomer] = useState<DroppedInCustomer>(null);
  const [triggerResetSearch, setTriggerResetSearch] = useState<boolean>(false);
  const [triggerResetSelection, setTriggerResetSelection] = useState<boolean>(false);
  const [selectedVendors, setSelectedVendors] = useState<IARVendor[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const value = useMemo(() => ({
    initialVendors, setInitialVendors,
    customersList, setCustomersList,
    filteredCustomersList, setFilteredCustomersList,
    vendorsList, setVendorsList,
    filteredVendorsList, setFilteredVendorsList,
    isFetching, setIsFetching,
    selectedCustomerWithChildAndVendor, setSelectedCustomerWithChildAndVendor,
    vendorRemoveConfirmModalOpen, setVendorRemoveConfirmModalOpen,
    droppedInCustomer, setDroppedInCustomer,
    triggerResetSearch, setTriggerResetSearch,
    triggerResetSelection, setTriggerResetSelection,
    selectedVendors, setSelectedVendors,
    isDragging, setIsDragging,
  }), [
    initialVendors, setInitialVendors,
    customersList, setCustomersList,
    filteredCustomersList, setFilteredCustomersList,
    vendorsList, setVendorsList,
    filteredVendorsList, setFilteredVendorsList,
    isFetching, setIsFetching,
    selectedCustomerWithChildAndVendor, setSelectedCustomerWithChildAndVendor,
    vendorRemoveConfirmModalOpen, setVendorRemoveConfirmModalOpen,
    droppedInCustomer, setDroppedInCustomer,
    triggerResetSearch, setTriggerResetSearch,
    triggerResetSelection, setTriggerResetSelection,
    selectedVendors, setSelectedVendors,
    isDragging, setIsDragging,
  ]);

  return (
    <EditVendorCustomerContext.Provider value={value}>
      {children}
    </EditVendorCustomerContext.Provider>
  );
}

export default EditVendorCustomerProvider;