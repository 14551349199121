import { useState, useEffect, useContext, Dispatch, SetStateAction } from "react";
import { AlertColor, Box, Chip, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IClientInfo, TableRowProps } from "../../../../../../interfaces/clientListInterface";
import SettingsIcon from '@mui/icons-material/Settings';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import ConfirmModal from "../../../../../modals/confirm-modal";
import { StyledTableCell, StyledTableRow } from '../styled-components';
import ClientSettings from "../../../details";
import styles from './styles'
import { ClientContext } from "../../../../../../context/clientContext";
import { DraggableListItem } from "../../../../../../interfaces/draggableList";
import { API_DOMAIN, DELETE, PERMISSIONS, PUT, clientColumnList } from "../../../../../../utility/constants";
import { SelectedClientContext } from "../../../../../../context/selectedClientContext";
import { checkUserPermissions, getLocalStorageItem, setLocalStorageItem } from "../../../../../../utility/helper";
import axiosInstance from "../../../../../../service/axiosInstance";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GoToFileImport from '../../../../../../assets/images/GoToFileImport.svg'
import GoToIneligibleSettings from '../../../../../../assets/images/GoToIneligibleSettings.svg'
import WarningModal from "../../../warning-modal";
import GoToIneligibleSettingsDisabled from '../../../../../../assets/images/GoToIneligibleSettingsDisabled.svg'

interface IToasterProps {
  isToasterOpen     : boolean;
  setIsToasterOpen  : Dispatch<SetStateAction<boolean>>;
  toasterMessage    : string;
  setToasterMessage : Dispatch<SetStateAction<string>>;
  toasterSeverity   : AlertColor;
  setToasterSeverity: Dispatch<SetStateAction<AlertColor>>;
}

export interface IClientsTableRowProps extends TableRowProps, IToasterProps {};

/**
 * Component for showing the contents of the row in the client table.
 * @param props The props for the Row component in the clients page. See the IClientsTableRowProps interface for more information.
 */
const Row = (props: IClientsTableRowProps) => {
  const { 
    type,
    row,
    sortedAccountingSystems,
    sortedLineOfBusiness,
    reportFrequencies,
    naicsCodes,
    currencies,
    refreshRow,
    setRefreshRow ,
    page,
    length
  } = props;
  const navigate                                    = useNavigate();
  const { list,
          setList,
          canArchiveDeleteClient,
          canViewClientSettings,
          clientInfo }                              = useContext(ClientContext);
  const selectedClientContext                       = useContext(SelectedClientContext);
  const [open, setOpen]                             = useState<boolean>(false);
  const [edit, setEdit]                             = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen]               = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal]           = useState<boolean>(false);
  const [modalTitle, setModalTitle]                 = useState<string>('');
  const [modalDescription, setModalDescription]     = useState<string>('');
  const [modalConfirmText, setModalConfirmText]     = useState<string>('Delete');
  const [client, setClient]                         = useState<IClientInfo>(row);
  const [selectedColumns, setSelectedColumns]       = useState<DraggableListItem[]>(list.filter(item => item.checked));
  const [anchorEl, setAnchorEl]                     = useState<null | HTMLElement>();
  const [isClicked, setIsClicked]                   = useState<boolean>(false)
  const [archiveWarningOpen, setArchiveWarningOpen] = useState<boolean>(false);
  const [archiveDescription, setArchiveDescription] = useState<string>('');
  const [archiveDeleting, setArchiveDeleting]       = useState<boolean>(false);
  const [archiveDelete, setArchiveDelete]           = useState<'archive' | 'delete'>('archive');
  
  /**
   * This useEffect hook calls the multipleStates function whenever the row, page, or length changes.
   */
  useEffect(() => {
    multipleStates(false, false)
  }, [row, page, length])

  /**
   * This useEffect hook sets the current client for the row.
   */
  useEffect(() => {
    setClient(row);
  }, [row])

  /**
   * This useEffect hook sets the selected columns to be shown in the row.
   */
  useEffect(() => {
    const columns = list.filter(item => item.checked);
    setSelectedColumns(columns);
  }, [list]);

    /**
   * This useEffect hook sets the list's value from the stored clientColumnList. 
   * This prevents the colum list from resetting it's values when the page is refreshed.
   */
    useEffect(() => {
      const storedList = getLocalStorageItem('clientColumnList');
      if (storedList) {
        setList(JSON.parse(storedList)); 
      }
    }, []);
  
    
  /**
   * This function sets the open status and the edit status of the client details modal.
   * @param open The open status of the client details modal of the client in the row.
   * @param edit The edit status of the client details modal of the client in the row.
   */
  const multipleStates = (open: boolean, edit: boolean) => {
    setOpen(open);
    setEdit(edit);
  };

  /**
   * This useEffect sets the props values from props. It triggers after the achor for the sub menu was set.
   */
  useEffect(() => {
    if(!anchorEl && isClicked) {
      setIsClicked(false)
    }
  }, [anchorEl, isClicked])

  /**
   * This function open or close the sub menu.
   * 
   * @param event MouseEvent onClick
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
    setIsClicked(true)
  };

  /**
   * This function updates the details of the current client.
   * @param updatedClient The updated client.
   */
  const updateClient = (updatedClient : Partial<IClientInfo>) => {
    if (client.recordId === updatedClient.recordId) {
      setClient({
        ...client,
        ...updatedClient
      })
    }
  }

  /**
   * This function retrieves a certain property of a client.
   * @param value The property of the client to be retrieved.
   * @returns The value of the property.
   */
  const getClientData = (value : string) => {
    if (value === 'accountingSystem.accountingSystemName') {
       return client['accountingSystem'] !== undefined ? client['accountingSystem']['accountingSystemName'] : '';
    } else if (value === 'lineLimit') {
      const currencyFormattedString = new Intl.NumberFormat(`en-US`, {
        currency: `USD`,
        style: `currency`,
      }).format(client.lineLimit);
      return currencyFormattedString;
    } else if (value === 'clientContactPhoneNumber') {
      let phoneNumber = RegExp(/^(\d{3})(\d{3})(\d{4})$/).exec(client.clientContactPhoneNumber);
      if (phoneNumber) {
        return '+(' + phoneNumber[1] + ') ' + phoneNumber[2] + '-' + phoneNumber[3];
      } else {
        return '';
      };
    } else if (value === 'parentClientFk') {
      if (client.parentClientFk !== undefined) {
        const parentRecordId = client.parentClientFk;
        const parentClient = clientInfo.find(clientInfo => clientInfo.recordId === parentRecordId);
        return parentClient?.borrowerName;
      } else {
        return '';
      }
    }else {
      let key = value as keyof IClientInfo;
      const clientValue = client[key];

      if (typeof clientValue === 'string' || typeof clientValue === 'number') {
        return clientValue.toString();
      } else {
        return '';
      }
    }
  }
  
  /**
   * This function calls the API endpoint for deleting a client.
   */
  const deleteClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/deleteParentClient`,
            method: DELETE,
            params: {parentClientFk: client.recordId}
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}`,
            method: DELETE,
          });
        }
        setRefreshRow(!refreshRow)
        props.setToasterMessage(`${client.borrowerName} has been deleted.`);
        props.setToasterSeverity('success');
        props.setIsToasterOpen(true);
        
        // if client is selected client then remove set selected client to null
        handleDeleteSelectedClient();
      } catch (e) {
        console.log(e);
        props.setToasterMessage(`Failed to delete ${client.borrowerName}. Please check your inputs!`);
        props.setToasterSeverity('error');
        props.setIsToasterOpen(true);
      }
      closeModal();
    } else {
      props.setToasterMessage('You do not have the necessary permissions for this action.');
      props.setToasterSeverity('error');
      props.setIsToasterOpen(true);
    }
  };

  /**
   * This function calls the API endpoint for archiving a client.
   */
  const archiveClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    setArchiveDeleting(true);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/updateArchiveStatusOfParent`,
            method: PUT,
            params: {parentClientFk: client.recordId, isArchive: true}
          }) 
        } else if (client.parentClientFk !== null && client.parentClientFk !== undefined) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archiveChild`,
            method: PUT
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archive`,
            method: PUT
          })
        }
        setArchiveDeleting(false);
        setArchiveWarningOpen(false);
        setRefreshRow(!refreshRow)
        props.setToasterMessage(`${client.borrowerName} has been archived.`);
        props.setToasterSeverity('success');
        props.setIsToasterOpen(true);
        
        // if client is selected client then remove set selected client to null
        handleDeleteSelectedClient();
      } catch (e) {
        console.log(e);
        setArchiveDeleting(false);
        setArchiveWarningOpen(false);
        props.setToasterMessage(`Failed to archive ${client.borrowerName}. Please check your inputs!`);
        props.setToasterSeverity('error');
        props.setIsToasterOpen(true);
      }
      closeModal();
    } else {
      setArchiveDeleting(false);
        setArchiveWarningOpen(false);
      props.setToasterMessage('You do not have the necessary permissions for this action.');
      props.setToasterSeverity('error');
      props.setIsToasterOpen(true);
    }
  };

  /**
   * This function calls the API endpoint for restoring a client.
   */
  const restoreClient = async () => {
    const canArchiveDelete = await checkUserPermissions(getLocalStorageItem('uid'), PERMISSIONS.DELETE_ARCHIVE_CLIENT);
    if (canArchiveDelete) {
      try {
        if (client.parentClient === true) {
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/updateArchiveStatusOfParent`,
            method: PUT,
            params: {parentClientFk: client.recordId, isArchive: false}
          })
        } else {  
          await axiosInstance.request({
            url: `${API_DOMAIN}/borrowers/${client.recordId}/archive?isArchived=false`,
            method: PUT
          });
        }
        setRefreshRow(!refreshRow)
        props.setToasterMessage(`${client.borrowerName} has been restored.`);
        props.setToasterSeverity('success');
        props.setIsToasterOpen(true);
      } catch (e) {
        console.log(e);
        props.setToasterMessage(`Failed to restore ${client.borrowerName}. Please check your inputs!`);
        props.setToasterSeverity('error');
        props.setIsToasterOpen(true);
      }
      closeModal();
    } else {
      props.setToasterMessage('You do not have the necessary permissions for this action.');
      props.setToasterSeverity('error');
      props.setIsToasterOpen(true);
    }
  };

  /**
   * This function sets the selected client to null if its the same as the deleted client of the row
   */
  const handleDeleteSelectedClient = () => {
    const childClientIds = selectedClientContext.clients.filter(contextClients => contextClients.parentClientFk === client.recordId).map(client => client.recordId);
    if (selectedClientContext.selectedClient?.recordId === client.recordId || childClientIds.includes(selectedClientContext.selectedClient?.recordId)) {
      selectedClientContext.setSelectedClient(null);
    }
  }

  /**
   * This function opens the client details modal of the client.
   */
  const handleOpen = () => {
    setOpen(!open);
  };

  /**
   * This function closes the confirm delete/archive/restore client modal.
   */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  /**
   * This function opens the confirm archive modal when the archive button is clicked.
   */
  const onArchiveClick = () => {
    if (client.parentClientFk !== null && client.parentClientFk !== undefined) {
      setArchiveDescription('You are about to archive a child client, and this action will result in the loss of all interconnected data. Are you sure you want to proceed?');
      setArchiveDelete('archive');
      setArchiveWarningOpen(true);
    } else if (client.parentClient === true) {
      setArchiveDescription(`You are about to archive ${client.borrowerName}, and this action will result in the archiving of all child clients associated with it and the loss of interconnected data of each client. Are you sure you want to proceed?`);
      setArchiveDelete('archive');
      setArchiveWarningOpen(true);
    } else {
      setModalTitle(`Archive ${client.borrowerName}`);
      setModalDescription(`Are you sure you want to archive the ${client.borrowerName} Client? This action will result in archiving all collateral under this Client.`);
      setModalConfirmText('Archive')
      setIsDeleteModal(false)
      setIsModalOpen(true)
    }
  }

  /**
   * This function opens the confirm restore modal when the restore button is clicked.
   */
  const onRestoreClick = () => {
    setModalTitle(`Restore ${client.borrowerName}`);
    if (client.parentClient === true) {
      setModalDescription(`You are about to restore the ${client.borrowerName} Client, which will result in the restoring of all clients and their collateral under this Parent. Are you sure about this action?`)
    } else {
      setModalDescription(`Are you sure you want to restore the ${client.borrowerName} Client? This action will result in restoring all collateral under this Client.`);
    }
    setModalConfirmText('Restore')
    setIsDeleteModal(false)
    setIsModalOpen(true)
  }

  /**
   * This function opens the confirm delete modal when the delete button is clicked.
   */
  const onDeleteClick = () => {
    if (client.parentClient === true) {
      setArchiveDescription(`You are about to delete ${client.borrowerName}, and this action will result in the deletion of all child clients that are associated with it. Are you sure you want to proceed?`);
      setArchiveDelete('delete');
      setArchiveWarningOpen(true);
    } else {
      setModalTitle(`Delete ${client.borrowerName}`);
      setModalDescription(`Are you sure you want to delete the ${client.borrowerName} Client? This action will result in deleting all collateral under this Client.`);
      setModalConfirmText('Delete')
      setIsDeleteModal(true)
      setIsModalOpen(true)
    }
  }

  /**
   * This function calls the archive/restore/delete client functions when the confirm delete/archive/restore modal is accepterd.
   * @returns The delete, archive, or restore function.
   */
  const handleConfirm = () => {
    if (modalConfirmText === 'Archive') {
      return archiveClient();
    } else if (modalConfirmText === 'Restore') {
      return restoreClient();
    } else {
      return deleteClient();
    }
  }

  /**
   * This function is called whenever the confirm delete/archive/restore for the warning modal is clicked.
   * @returns The delete, or archive function.
   */
  const handleConfirmWarning = () => {
    if (archiveDelete === 'archive') {
      return archiveClient();
    }  else {
      return deleteClient();
    }
  }

  /**
   * This function navigates the user to the client settings page of the selected client.
   */
  const selectClient = () => {
    selectedClientContext?.setSelectedClient(client);
    navigate(`/clients/${row.recordId}/settings`);
  }

  /**
   * This function navigates the user to the file import page of the selected client.
   */
  const navigateFileImport = () => {
    selectedClientContext?.setSelectedClient(client);
    setTimeout(()=>{navigate(`/file-import`)}, 1);
  }

  /**
   * This function navigates the user to the ineligible settings page of the selected client.
   */
  const navigateIneligibleSettings = () => {
    selectedClientContext?.setSelectedClient(client);
    navigate(`/ineligible-settings`);
  }

  return (
    <>
      <WarningModal
        open={archiveWarningOpen}
        onClose={() => {setArchiveWarningOpen(false)}}
        onConfirm={() => {handleConfirmWarning()}}
        description={archiveDescription}
        isDeleting={archiveDeleting}
      />
      <ConfirmModal
        title={modalTitle}
        description={modalDescription}
        open={isModalOpen}
        alignment="left"
        onClose={closeModal}
        onConfirm={handleConfirm}
        errorButton={isDeleteModal}
        noButtonText={'Cancel'}
        yesButtonText={modalConfirmText}
      />
      <ClientSettings
        type={type}
        updateClient={updateClient}
        edit={edit}
        setEdit={setEdit}
        open={open}
        setOpen={setOpen}
        client={client}
        accountingSystems={sortedAccountingSystems}
        lineOfBusinesses={sortedLineOfBusiness}
        reportFrequencies={reportFrequencies}
        naicsCodes={naicsCodes}
        currencies={currencies}
        refreshRow={refreshRow}
        setRefreshRow={setRefreshRow}
      />
      <StyledTableRow>
        {
          selectedColumns.map(column => {
            return (
              <StyledTableCell
                tabIndex={0}
                key={column.recordId}
                sx={{...styles.cursor, ...(column.value === 'lineLimit' && styles.rightAlign)}}
                onClick={handleOpen}
              >
                {getClientData(column.value)}
                {(column.name === "Client Name" && client.parentClient) && <Chip size='small' sx={styles.parentIdentifierChip} />}
              </StyledTableCell>
            )
          })
        }
        {
          (type === 'active') ?
          (
            <StyledTableCell align='center' width='10%'>
              {
                canViewClientSettings && 
                  <Tooltip title='Go to the Client Settings page'>
                    <IconButton
                      data-testid='settings'
                      aria-label='Client Settings icon'
                      size='small'
                      onClick={selectClient}
                    >
                      <SettingsIcon sx={styles.iconColor} />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={'Go To File Import'}>
                <span>
                  <IconButton
                    data-testid='item-file-import'
                    aria-label='Go To File Import icon'
                    size='small'
                    onClick={() => navigateFileImport()}
                  >
                    <img
                      height={22}
                      src={GoToFileImport}
                      alt="go-to-file-import-icon"
                      aria-label='Go To File Import icon'
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={(client.parentClientFk !== null && client.parentClientFk !== undefined) ? 'Not Applicable for Child Clients' : 'Go To Ineligible Settings'}>
                <span>
                  <IconButton
                    data-testid='item-ineligible-settings'
                    aria-label='Go To Ineligible Settings icon'
                    size='small'
                    disabled={client.parentClientFk !== null && client.parentClientFk !== undefined}
                    onClick={() => navigateIneligibleSettings()}
                  >
                    <img
                      height={22}
                      src={(client.parentClientFk !== null && client.parentClientFk !== undefined) ? GoToIneligibleSettingsDisabled : GoToIneligibleSettings}
                      alt="go-to-ineligible-settings-icon"
                      aria-label='Go To Ineligible Settings icon'
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title='More Actions'>
                <IconButton
                  aria-label='More Actions icon'
                  data-testid='more-actions'
                  size='small'
                  disabled={open}
                  onClick={handleClick}
                >
                  <MoreVertIcon sx={styles.iconColor} />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                data-testid={`actions-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  data-testid={`item-view`} 
                  disabled={open}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEl(null);
                    multipleStates(true, false);
                  }}
                >
                  <PreviewIcon sx={{...styles.iconColor, ...styles.paddingRight, ...(open && styles.disabledIcon)}} />
                  View Client Information
                </MenuItem>
                {canArchiveDeleteClient && (
                  <>
                    { client.default ? (
                      <Tooltip title={`Cannot ${client.canDelete ? 'aelete' : 'archive'} default child clients`}>
                        <Box>
                          <MenuItem
                            data-testid={client.canDelete ? `item-delete` : `item-archive`}
                            disabled
                          >
                            {client.canDelete ? <DeleteOutlineOutlinedIcon sx={{...styles.iconColor, ...styles.paddingRight}} /> : 
                                                <Inventory2OutlinedIcon sx={{...styles.iconColor, ...styles.paddingRight}} />}
                            {client.canDelete ? 'Delete Client' : 'Archive Client'}
                          </MenuItem>
                        </Box>
                      </Tooltip>
                    ) : (
                      <MenuItem
                        data-testid={client.canDelete ? `item-delete` : `item-archive`} 
                        onClick={(e) => {
                          e.stopPropagation();
                          setAnchorEl(null);
                          client.canDelete ? onDeleteClick() : onArchiveClick();
                        }}
                      >
                        {client.canDelete ? <DeleteOutlineOutlinedIcon sx={{...styles.iconColor, ...styles.paddingRight}} /> : 
                                            <Inventory2OutlinedIcon sx={{...styles.iconColor, ...styles.paddingRight}} />}
                        {client.canDelete ? 'Delete Client' : 'Archive Client'}
                      </MenuItem>
                    ) }
                  </>
                )}
              </Menu>
            </StyledTableCell>
          ) : (
            <StyledTableCell align='center' width='10%'>
              {canArchiveDeleteClient && (
                <Tooltip title='Restore the Client'>
                  <IconButton
                    aria-label='Restore icon'
                    size='small'
                    onClick={onRestoreClick}
                  >
                    <RestoreOutlinedIcon sx={{...styles.iconColor}} />
                  </IconButton>
                </Tooltip>
              )}
            </StyledTableCell>
          )
        }
      </StyledTableRow>
    </>
  );
};

export default Row;