import colors from "../../../utility/colors";

const styles = {
  navBox: {
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center', 
    mb: '1rem'
  },
  button: {
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
  },
  buttonText: {
    fontSize: '14px',
    color: colors.WHITE,
    textTransform: 'none',
  },
  emptyButtonBox: {
    width: '8.6rem',
  },
  tabBox: {
    width: '100%',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.NEUTRAL}`,
    borderRadius: '3px 3px 0 0',
  },
  buttonSubTabs: {
    marginLeft: '24px',
  },
  boxSubTabs: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '24px',
  },
  buttonTabs: {
    '.MuiTabs-Box': {
      border: `1px solid ${colors.NEUTRAL}`,
    },
    '.MuiTabs-scroller': {
      background: '#F7F7F7',
    },
    '.MuiButtonBase-root.MuiTab-root': {
      borderRadius: '3px 3px 0 0',
      textTransform: 'none',
      width: '220px',
      marginRight: '5px',
      padding: '8px',
      boxShadow: `0 -5px 30px -7px ${colors.NEUTRAL}`,
      color: colors.PRIMARY_TEXT,
    },
    '.MuiButtonBase-root.MuiTab-root.Mui-selected': {
      background: colors.WHITE,
      color: colors.PRIMARY_TEXT,
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
    '.MuiTabs-flexContainer': {
      paddingTop: '10px',
    },
  },
  noPermissionBox: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center'
  },
  clearButton: {
    color: colors.PRIMARY_LIGHT,
    fontSize: '14px',
  },
  boxActionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginX: '24px',
    height: '48px',
    backgroundColor: colors.BACKGROUND_COLOR,
  },
}
export default styles;