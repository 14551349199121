import { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Paper, Skeleton, Stack, Switch, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { Field, FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { NON_EXISTING } from '../../../utility/constants';
import { FieldList, FieldValue, IFormikValuesForRuleAndOverridesPerSetting, IFormikValuesForUPCRuleAndOverridesPerSetting, IIneligibleCondition, IIneligibleRuleConditionGroup, IIneligibleRuleOverride, IIneligibleSettingsContext, IIneligibleSettingsPermissions, IneligibleRuleAPI, OverrideCriteriaProps } from '../../../interfaces/ineligibleSettingInterface';
import { IneligibleSettingsContext } from '../../../context/ineligibleSettingsContext';
import AddCriteriaButton from './add-override-button';
import DelinquentSettings from './special-settings/delinquent-settings';
import CrossAgeSettings from './special-settings/cross-age-settings';
import ConcentrationSettings from './special-settings/concentration-settings';
import CreditLimitSettings from './special-settings/credit-limit-settings';
import { RemoveItemModal } from '../modals';
import ConditionRow from './condition-row';
import styles from './styles';
import ContraSettings from './special-settings/contra-settings';
import ViewApplicableCustomers from './view-applicable-customers';
import { getInitialOverride, initialCondition } from '../form-container';
import { SelectedClientContext } from '../../../context/selectedClientContext';
import { getFieldList, getFieldListByParentClient, getFieldTables, getFieldValuesByTableField, getFieldValuesByTableFieldAndParentClient, getParentCustomersByARCollateralId, getUPCParentCustomersByBorrowerId, getUserDefinedFieldList, getUserDefinedFieldListByParentClient } from '../../../api/ineligible-settings';
import { IARCollateral, IARCustomer, IClient } from '../../../interfaces';

export interface OverrideSettingsProps {
  idx:    number,
  formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>;
}

/**
 * This function renders a special settings component based on the provided code.
 * @param code The code specifying the type of special settings to render.
 * @param index The index for identifying the settings.
 * @param formik Formik props for the current form.
 * @returns The rendered special settings component or null if no match is found.
 */
const renderOverrideSettings = (code: string, index: number, formik: FormikProps<IFormikValuesForRuleAndOverridesPerSetting> | FormikProps<IFormikValuesForUPCRuleAndOverridesPerSetting>) => {
  switch (code) {
    case 'DLQ':
      return(
        <DelinquentSettings
          idx={index}
          formik={formik}
        />
      )
    case 'XA':
      return(
        <CrossAgeSettings
          idx={index}
          formik={formik}
        />
      )
    case 'CN':
      return(
        <ConcentrationSettings
          idx={index}
          formik={formik}
        />
      )
    case 'CL':
      return(
        <CreditLimitSettings
          idx={index}
          formik={formik}
        />
      )
    default:
      break;
  }
}

/**
 * Component for rendering and managing override criteria for an ineligible rule.
 * @param props The component props.
 * @returns The JSX element representing the IneligibleRuleOverride component.
 */
const IneligibleRuleOverride: React.FC<OverrideCriteriaProps> = (props) => {
  const { formik }                                  = props;
  const { selectedClient }                          = useContext(SelectedClientContext);
  const {
    selectedCollateral,
    fieldTables, setFieldTables,
    fieldList, setFieldList,
    userDefinedFieldList, setUserDefinedFieldList,
    parentCustomers, setParentCustomers,
    fieldValues, setFieldValues,
    isIneligibleDetailsLoading,
    selectedIneligibleIndex: ineligibleIndex,
    permissions, hasPaymentTerms,
  }                                                 = useContext(IneligibleSettingsContext) as IIneligibleSettingsContext;

  const [removeItemModal, setRemoveItemModal]       = useState<{isOpen: boolean, selectedId: number}>({ isOpen: false, selectedId: NON_EXISTING });

  /**
   * This function retrieves the selected ineligible rule from Formik values.
   * @returns The selected ineligible rule or undefined if not found.
   */
  const getSelectedIneligibleRule = () => formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRule as IneligibleRuleAPI;

  /**
   * This function retrieves the selected ineligible rule overrides from Formik values.
   * @returns The selected ineligible rule overrides or undefined if not found.
  */
  const getSelectedIneligibleRuleOverrides = () => formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[];

  useEffect(() => {
    if (isIneligibleDetailsLoading) { return; }
    const overrides = getSelectedIneligibleRuleOverrides();
    if (overrides === undefined || overrides.length === 0) { return; }
    loadFieldRelatedDependencies();
  }, [isIneligibleDetailsLoading, formik.values.ineligibleSettingDetails[ineligibleIndex], fieldTables.length])

  /**
   * This function generates the Formik field name for a selected condition group.
   * @param idx The index of the rule override.
   * @param idx2 The index of the condition group.
   * @returns The Formik field name.
   */
  const getFormikFieldNameForSelectedConditionGroup = (idx: number, idx2: number) => {
    return (
      `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup[${idx2}]`
    );
  }

  const getFormikFieldNameForExcludedCustomersOnSelectedOverride = (idx: number) => `ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].excludedCustomers`;

  const isSelectedClientUPC = () => (selectedClient as IClient).parentClient;

  const loadFieldRelatedDependencies = async () => {
    if ([fieldTables, fieldList, userDefinedFieldList, parentCustomers].every(list => list !== 'UNLOADED')) {
      const loadedFieldValues = await loadFieldValuesPerFieldName(fieldList as FieldList[], parentCustomers as IARCustomer[]);
      setFieldValues(loadedFieldValues);
      return;
    };

    const [
      loadedFieldTables, loadedFieldList,
      loadedUserDefinedFieldList,
      loadedParentCustomers
    ] = isSelectedClientUPC()
      ? await Promise.all([
        getFieldTables(), getFieldListByParentClient((selectedClient as Required<IClient>).recordId),
        getUserDefinedFieldListByParentClient((selectedClient as Required<IClient>).recordId),
        getUPCParentCustomersByBorrowerId((selectedClient as Required<IClient>).recordId)
      ])
      : await Promise.all([
        getFieldTables(), getFieldList(selectedCollateral as IARCollateral),
        getUserDefinedFieldList(selectedClient as Required<IClient>),
        getParentCustomersByARCollateralId((selectedCollateral as IARCollateral).recordId)
      ]);

    const loadedFieldValues = await loadFieldValuesPerFieldName(loadedFieldList, loadedParentCustomers);

    const allFieldList = [
      ...loadedFieldList,
      ...loadedUserDefinedFieldList.map(field => ({
          documentTypeFk: field.documentTypeFk, tableField: field.fieldName,
          description: field.fieldName, fieldType: field.fieldType, isUserDefined: true,
      }))
    ].sort((a, b) => a.description.localeCompare(b.description)) as FieldList[];

    setFieldTables(loadedFieldTables);
    setFieldList(allFieldList);
    setUserDefinedFieldList(loadedUserDefinedFieldList);
    setParentCustomers(loadedParentCustomers);
    setFieldValues(loadedFieldValues);
  };

  const loadFieldValuesPerFieldName = async (fieldList: FieldList[], parentCustomers: IARCustomer[]) => {
    const hasMatchingField = (formConditions: IIneligibleCondition[], tableField: string) =>
      formConditions.some(condition => condition.field === tableField);

    const hasMatchingConditionGroup = (formConditionGroups: IIneligibleRuleConditionGroup[], tableField: string) =>
      formConditionGroups.some(conditionGroup => hasMatchingField(conditionGroup.ineligibleRuleCondition, tableField));

    const hasMatchingOverride = (formOverrides: IIneligibleRuleOverride[], tableField: string) =>
      formOverrides.some(formOverride => hasMatchingConditionGroup(formOverride.ineligibleRuleConditionGroup, tableField));

    const formFieldNames = fieldList.filter(field => {
      const isFieldValuesForFieldNameLoaded = fieldValues[field.tableField] !== undefined;
      const formOverrides = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides as IIneligibleRuleOverride[];
      return isFieldValuesForFieldNameLoaded || hasMatchingOverride(formOverrides, field.tableField);
    });

    const fieldValuesPerFieldName: { [key: string]: FieldValue[] } = {};

    for (const currentFieldName of formFieldNames) {
      const loadedFieldValues = await loadFieldValues(currentFieldName, parentCustomers);
      fieldValuesPerFieldName[currentFieldName.tableField] = loadedFieldValues;
    }

    return fieldValuesPerFieldName;
  };

  const loadFieldValues = async (field: FieldList, parentCustomers: IARCustomer[]) => {
    if (fieldValues[field.tableField] !== undefined) { return fieldValues[field.tableField]; }
    const loadedFieldValuesByTableField = isSelectedClientUPC()
      ? await getFieldValuesByTableFieldAndParentClient((selectedClient as Required<IClient>).recordId, field, parentCustomers)
      : await getFieldValuesByTableField(selectedCollateral as IARCollateral, field, parentCustomers);
    return loadedFieldValuesByTableField;
  };

  /**
   * This function checks if the selected ineligible rule has special settings.
   * @returns True if the ineligible rule has special settings, otherwise false.
   */
  const hasSpecialSettings = () => {
    const codes = ['DLQ', 'XA', 'CN', 'CL'];
    const withSpecialSettings = codes.includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code);
    return withSpecialSettings
  };

  /**
   * This function adds a new condition group to the selected ineligible rule override.
   * @param ineligibleRuleOverride The selected ineligible rule override.
   * @returns The new condition group.
   */
  const addNewGroup = (ineligibleRuleOverride: IIneligibleRuleOverride) => {
    const [lastGroup] = ineligibleRuleOverride.ineligibleRuleConditionGroup.slice(-1);
    const newConditionGroup : IIneligibleRuleConditionGroup = {
      irConditionGroupId: NON_EXISTING,
      irOverrideId: ineligibleRuleOverride.ineligibleRuleOverrideId,
      order: lastGroup.order + 1,
      logicalOperator: 'AND',
      ineligibleRuleCondition: [initialCondition]
    }
    return newConditionGroup;
  };

  /**
   * This function handles the removal of a rule override.
   * @param idx The index of the rule override to remove.
   * @param ruleOverrideHelpers Formik FieldArray helpers.
   */
  const handleConfirmRemoveOverride = (idx: number, ruleOverrideHelpers: FieldArrayRenderProps) => {
    ruleOverrideHelpers.remove(idx)
  };

  const isIneligibleLoading = () => {
    if(isIneligibleDetailsLoading) { return true; }
    const isIneligibleDevoidOfOverrides = ['AC', 'CONTRA', 'RFV'].includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code);
    if (isIneligibleDevoidOfOverrides) { return false; }
    const isOverrideLoading = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleRuleOverrides === undefined;
    if (isOverrideLoading) { return true; }
    const isDelinquentLoading = formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code === 'DLQ' && hasPaymentTerms === 'UNLOADED';
    return isDelinquentLoading;
  };

  /**
   * This function generates the content to render based on the loading state.
   * @returns The loading content or override content.
   */
  const getRenderedContent = () => {
    if (isIneligibleLoading()) {
      return renderLoadingContent();
    }
    if (['AC', 'RFV'].includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code)) { return null; }
    if (['CONTRA'].includes(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code)) { return <ContraSettings />; }
    return renderOverrideContent();
  };

  /**
   * This function generates the loading content with skeleton loaders.
   * @returns The loading content with skeleton loaders.
   */
  const renderLoadingContent = () => {
    return (
      <Box data-testid='loading-for-ineligible-rule-override'>
        <Stack sx={styles.skeletonLoaderContainer}>
          <Skeleton variant='rectangular' sx={styles.skeletonLoader} />
          <Skeleton variant='rectangular' sx={styles.shortSkeletonLoader} />
        </Stack>
        <Stack sx={styles.skeletonLoaderContainer}>
          <Skeleton variant='rectangular' sx={styles.skeletonLoader} />
          <Skeleton variant='rectangular' sx={styles.shortSkeletonLoader} />
        </Stack>
        <Stack sx={styles.skeletonLoaderContainer}>
          <Skeleton variant='rectangular' sx={styles.skeletonLoader} />
          <Skeleton variant='rectangular' sx={styles.shortSkeletonLoader} />
        </Stack>
      </Box>
    );
  };

  /**
   * This function generates the override content for the selected ineligible rule.
   * @returns The override content.
   */
  const renderOverrideContent = () => {
    return (
      <FieldArray name={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides`}>
        {(ruleOverrideHelpers) => (
          <Box>
            {getSelectedIneligibleRuleOverrides()?.map((override, idx) =>
              <Box sx={styles.overrideHeader} key={override.ineligibleRuleOverrideId !== NON_EXISTING ? override.ineligibleRuleOverrideId :`new-override${idx}`}>
                <Paper sx={{ ...styles.overrideHeaderPaper, ...(idx === 0 && styles.overrideHeaderPaperForFirstIdx)}}>
                  <Grid container>
                    { renderCriteriaHeader(idx) }
                    <FieldArray name={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup`}>
                      {(conditionGroupHelpers: FieldArrayRenderProps) => renderCriteria(conditionGroupHelpers, override, idx)}
                    </FieldArray>
                  </Grid>
                </Paper>
                { renderRemoveItemModal(idx, ruleOverrideHelpers) }
              </Box>
            )}
            { renderAddCriteriaButton(ruleOverrideHelpers) }
          </Box>
        )}
      </FieldArray>
    );
  };

  /**
   * This function renders the criteria for a rule override.
   * @param conditionGroupHelpers Formik FieldArray helpers for condition groups.
   * @param override The selected ineligible rule override.
   * @param idx The index of the rule override.
   * @returns The rendered component.
   */
  const renderCriteria = (conditionGroupHelpers: FieldArrayRenderProps, override: IIneligibleRuleOverride, idx: number) => {
    return (
      <Grid item xs={12} container sx={styles.overrideConditionGroupGrid}>
        <Grid item xs={9}>
          { renderConditionGroup(conditionGroupHelpers, override, idx) }
        </Grid>
        <Grid item xs={3} sx={styles.overrideSpecialSettingsGrid}>
          <Grid container sx={styles.overrideSpecialSettingsGridContainer}>
            <Grid item xs={12} sx={styles.removeOverrideGroupIconContainer}>
              <IconButton
                disabled={formik.isSubmitting || formik.isValidating || isIneligibleDetailsLoading}
                sx={{...styles.removeOverrideGroupIconButton, ...(!(permissions as IIneligibleSettingsPermissions).canDeleteCriteriaAndSettings && styles.hidden)}}
                onClick={() => setRemoveItemModal({ isOpen: true, selectedId: idx })}
                aria-label='Remove icon'
                data-testid='remove-override-button'
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            { hasSpecialSettings() ? (
              <Grid item xs={12} sx={styles.overrideSpecialSettingsInnerGrid}>
                { renderOverrideSettings(formik.values.ineligibleSettingDetails[ineligibleIndex].ineligibleSetting.code ?? '', idx, formik) }
                <Box sx={styles.customerListViewGrid}>
                  <ViewApplicableCustomers formik={formik} idx={idx} />
                </Box>
              </Grid>
            ) : (
              <Box sx={styles.customerListViewGridForOtherSettings}>
                <ViewApplicableCustomers formik={formik} idx={idx} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  };

  /**
   * This function renders the condition groups for a rule override.
   * @param conditionGroupHelpers Formik FieldArray helpers for condition groups.
   * @param override The selected ineligible rule override.
   * @param idx The index of the rule override.
   * @returns The rendered components.
   */
  const renderConditionGroup = (conditionGroupHelpers: FieldArrayRenderProps, override: IIneligibleRuleOverride, idx: number) => override.ineligibleRuleConditionGroup.map((conditionGroup, idx2) =>
    <Box sx={styles.overrideConditionGroupBox} key={conditionGroup.irConditionGroupId !== NON_EXISTING ? conditionGroup.irConditionGroupId :`new-condition-group${idx2}`}>
      <Box sx={styles.overrideConditionBox}>
        <FieldArray name={`ineligibleSettingDetails[${ineligibleIndex}].ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup[${idx2}].ineligibleRuleCondition`}>
          {(conditionHelpers) =>
            <Box>
              {conditionGroup.ineligibleRuleCondition.map((condition, idx3) =>
                <ConditionRow
                  formik={formik}
                  idx={idx}
                  idx2={idx2}
                  idx3={idx3}
                  override={override}
                  conditionGroup={conditionGroup}
                  condition={condition}
                  conditionGroupHelpers={conditionGroupHelpers}
                  conditionHelpers={conditionHelpers}
                  newGroup={addNewGroup(getSelectedIneligibleRuleOverrides()[idx])}
                  key={condition.ineligibleRuleConditionId !== NON_EXISTING ? condition.ineligibleRuleConditionId :`new-condition${idx3}`}
                />
              )}
            </Box>
          }
        </FieldArray>
      </Box>
      <Grid container sx={styles.overrideAddNewGroupGrid}>
        { renderLogicalOperatorOrAddNewGroupButton(conditionGroupHelpers, override, idx, idx2) }
      </Grid>
    </Box>
  );

  /**
   * This function renders the logical operator or add new group button.
   * @param conditionGroupHelpers Formik FieldArray helpers for condition groups.
   * @param override The selected ineligible rule override.
   * @param idx The index of the rule override.
   * @param idx The index of the condition group.
   * @returns The rendered component.
   */
  const renderLogicalOperatorOrAddNewGroupButton = (conditionGroupHelpers: FieldArrayRenderProps, override: IIneligibleRuleOverride, idx: number, idx2: number) => {
    if (override.ineligibleRuleConditionGroup.length === 0 ||  override.ineligibleRuleConditionGroup.length === idx2 + 1) {
      return (
        <Button
          variant='contained'
          data-testid='add-group-button'
          sx={{...styles.saveButton, ...styles.overrideAddNewGroupButton, ...(!(permissions as IIneligibleSettingsPermissions).canAddCriteriaAndSettings && styles.hidden)}}
          onClick={() => {
            formik.setFieldValue(`${getFormikFieldNameForSelectedConditionGroup(idx, idx2)}.logicalOperator`, 'AND');
            formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
            conditionGroupHelpers.push(addNewGroup(getSelectedIneligibleRuleOverrides()[idx]));
          }}
        >
          +Add New Group
        </Button>
      );
    }
    return (
      <Field
        name={`ineligibleRuleOverrides[${idx}].ineligibleRuleConditionGroup[${idx2}].logicalOperator`}
        component={Switch}
        checked={getSelectedIneligibleRuleOverrides()[idx].ineligibleRuleConditionGroup[0].logicalOperator === 'AND'}
        value={getSelectedIneligibleRuleOverrides()[idx].ineligibleRuleConditionGroup[0].logicalOperator === 'AND'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          formik.setFieldValue(getFormikFieldNameForExcludedCustomersOnSelectedOverride(idx), []);
          getSelectedIneligibleRuleOverrides()[idx].ineligibleRuleConditionGroup.forEach((_, groupIndex) => {
            formik.setFieldValue(`${getFormikFieldNameForSelectedConditionGroup(idx, groupIndex)}.logicalOperator`, event.target.checked ? 'AND' : 'OR');
          });
        }}
        sx={{...styles.customSwitch, ...styles.overrideConditionOperator}}
      />
    );
  };

  /**
   * This function renders header for the criteria.
   * @param idx The index of the rule override.
   * @returns The rendered component.
   */
  const renderCriteriaHeader = (idx: number) => {
    if (idx !== 0) { return; }
    if (hasSpecialSettings()) {
      return (
        <Grid item xs={12} container sx={styles.overrideHeaderTextsContainer} >
          <Grid item xs={9} sx={styles.overrideCriteriaTitleGrid}>
            <Typography tabIndex={0} fontWeight={'bold'}>Override Criteria</Typography>
          </Grid>
          <Grid item xs={3} sx={styles.overrideSettingsTitleGrid}>
            <Typography tabIndex={0} fontWeight={'bold'}>Override Settings</Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12} container sx={styles.overrideHeaderTextsContainer} >
        <Grid item xs={9} sx={styles.overrideCriteriaTitleGrid}>
          <Typography tabIndex={0} fontWeight={'bold'}>Criteria</Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * This function generates the modal for removing a rule override.
   * @param idx The index of the rule override.
   * @param ruleOverrideHelpers Formik FieldArray helpers.
   * @returns The remove item modal.
   */
  const renderRemoveItemModal = (idx: number, ruleOverrideHelpers: FieldArrayRenderProps) => {
    return (
      <RemoveItemModal
        isOpen={removeItemModal.isOpen && removeItemModal.selectedId === idx}
        handleClose={() => setRemoveItemModal({ isOpen: false, selectedId: NON_EXISTING })}
        handleConfirm={() => handleConfirmRemoveOverride(removeItemModal.selectedId, ruleOverrideHelpers)}
      />
    )
  };

  /**
   * This function generates the button for adding new criteria to a rule override.
   * @param ruleOverrideHelpers Formik FieldArray helpers.
   * @returns The add criteria button.
   */
  const renderAddCriteriaButton = (ruleOverrideHelpers: FieldArrayRenderProps) => {
    return (
      <AddCriteriaButton
        ineligibleRuleId={getSelectedIneligibleRule()?.ineligibleRuleId ?? NON_EXISTING}
        ruleOverrideHelpers={ruleOverrideHelpers}
        initialOverride={getInitialOverride(hasPaymentTerms as boolean)}
        lastOverride={getSelectedIneligibleRuleOverrides()?.slice(-1)[0]}
      />
    )
  };

  return (
    <Box sx={styles.outerBox}>
      <Grid style={{ margin: '0.5rem', position: 'relative',}}>
        { getRenderedContent() }
      </Grid>
    </Box>
  )
}

export default IneligibleRuleOverride;
