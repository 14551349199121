import { Box } from "@mui/material";
import styles from "../analytics/styles";
import { TableauViz, TableauEventType, Toolbar } from '@tableau/embedding-api';
import { useEffect } from "react";
import api from "../../service/api";
import axiosInstance from "../../service/axiosInstance";
import { GET } from "../../utility/constants";
const Analytics = () => {

  useEffect(() => {
    showAnalytics();
  }, [])

  const showAnalytics = async () => {
    try {
      const response = await axiosInstance.request({
        url: api.GENERATE_TOKEN,
        method: GET,
        headers: { 'X-Timezone' : Intl.DateTimeFormat().resolvedOptions().timeZone }
      })

      const viz = new TableauViz();

      viz.src = 'https://10ay.online.tableau.com/t/loanwatch/views/AnalyticsDashboard/PortfolioView';
      viz.token = response.data;
      viz.toolbar = Toolbar.Hidden;

      const tableauViz = document.getElementById('tableauViz');
      if (tableauViz != null) {
        tableauViz.addEventListener(TableauEventType.VizLoadError, handleLoadError);
        tableauViz.append(viz);
      }
    } catch(e) {
      console.error(e);
    }
  }

  const handleLoadError = (e) => {
    const message = JSON.parse(e.detail.message);
    console.error(message);
  }

  return <Box sx={styles.container}>
    <div style={styles.iframe} id="tableauViz" data-testid="data-tableauViz"></div>
  </Box>
};

export default Analytics;