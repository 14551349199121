import { Box, Checkbox, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import images from "../../../../theme/images";
import styles from "./styles";
import { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { CustomerSettingsMainViewContext, ICustomerSettingsMainViewContext } from "../../../../context/customerSettingsMainViewContext";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../../context/customerSettingsContext";
import { SelectedClientContext } from "../../../../context/selectedClientContext";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { getUniqueKeys } from "../../../../utility/helper";
import { IARCustomer, IARVendor } from "../../../../interfaces";
import CustomerVendorRow from "./customer-vendor-row";
import { IHeadCell, ISortParams } from "..";

export interface CustomerVendorTableProps {
  headers: IHeadCell[];
  sortParams: ISortParams;
  setSortParams: Dispatch<SetStateAction<ISortParams>>;
  archive?: boolean;
  type: 'customer' | 'vendor';
  viewByParent?: boolean;
  viewByCustomer?: boolean;
}

const CustomerVendorTable = (props: CustomerVendorTableProps) => {
  const {
    archive,
    headers,
    sortParams, setSortParams,
    viewByParent,
    viewByCustomer
  } = props;

  // Contexts
  const { selectedClient } = useContext(SelectedClientContext);

  const {
    canViewCustomerInformation
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;
  
  const {
    setActionPanel,
    selectAll, setSelectAll,
    customerVendorList, setCustomerVendorList,
    displayNoTable,
    isFetching,
    triggerSelectAll, setTriggerSelectAll,
  } = useContext(CustomerSettingsMainViewContext) as ICustomerSettingsMainViewContext;

  const handleIcon = useCallback(() =>
    <UnfoldMoreIcon
      sx={styles.iconDefaultSort}
      aria-label='Sort option icon'
    />
  , []);

  const getIconComponent = (headerId: string) => {
    if (sortParams.sortBy === headerId) {
      return;
    } else {
      return { IconComponent: handleIcon };
    }
  };

  const handleSort = (headerId: string) => {
    if (sortParams.sortBy === headerId) {
      setSortParams({
        sortBy: headerId,
        direction: sortParams.direction === 'DESC' ? 'ASC' : 'DESC'
      });
    } else {
      setSortParams({
        sortBy: headerId,
        direction: 'ASC'
      });
    }
  };

  const getHeaderWidths = () => {
    if (viewByParent) {
      return '42.5%';
    } else if (viewByCustomer) {
      return '28%';
    } else if (selectedClient?.parentClient) {
      return '15%';
    } else {
      return '25%';
    }
  }

  const getSkeletonColumnsCount = () => {
    if (viewByParent) return 5;
    else if (viewByCustomer) return 6;
    else return 7;
  }

  const getTableBody = () => {
    if (isFetching) {
      return (
        <TableContainer
          component={Paper}
          sx={styles.tableContainer}
        >
          <Table aria-label='customized table' size='small'>
            <TableBody>
              {[...Array(10)].map((row) => (
                <TableRow key={getUniqueKeys(row)}>
                  {[...Array(getSkeletonColumnsCount())].map((cell) => (
                    <TableCell key={getUniqueKeys(cell)} sx={styles.tableCell}>
                      <Skeleton />
                    </TableCell>))}
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else if (displayNoTable) {
      return (
        <TableContainer
          component={Paper}
          sx={styles.tableContainerEmpty}
        >
          <Box
            component='img'
            sx={{ maxHeight: '250px' }}
            src={images.CustomerSettingsEmpty}
          />
          <Typography
            tabIndex={0}
            align='center'
          >
            No data available
          </Typography>
        </TableContainer>
      )
    } else if (customerVendorList.length) {
      return (
        <TableContainer
          component={Paper}
          sx={styles.tableContainer}
        >
          <Table aria-label='customized table' size='small'>
            <TableHead>
              <TableRow sx={styles.tableHeadRow}> 
              <TableCell sx={styles.tableHead} />
              <TableCell sx={styles.tableHeadSelectTag}>
                {!(viewByParent || viewByCustomer) &&
                <Checkbox 
                  checked={selectAll || [...customerVendorList].every(item => item.selected)}
                  data-testid={`select-all-checkbox`}
                  onClick={() => {
                    const allChecked = selectAll || [...customerVendorList].every(item => item.selected);

                    if (!allChecked) {
                      setCustomerVendorList(customerVendorList.map((data) => ({
                        ...data,
                        selected: true,
                      })));
                      setActionPanel({
                        restore: Boolean(archive),
                        open: true,
                      });
                    } else {
                      setCustomerVendorList(customerVendorList.map((data) => ({
                        ...data,
                        selected: false,
                      })));
                      setActionPanel({
                        restore: Boolean(archive),
                        open: false,
                      });
                    }

                    setTriggerSelectAll(!triggerSelectAll);
                    setSelectAll(!allChecked);
                  }} 
                />}
              </TableCell>
              {headers.map(header => 
                (<TableCell
                    width={getHeaderWidths()}
                    key={header.id}
                    sx={styles.tableHead}
                  >  
                    <TableSortLabel
                      active={sortParams.sortBy === header.id}
                      direction={sortParams.direction === 'DESC' ? 'desc' : 'asc'}
                      onClick={() => handleSort(header.id)}
                      aria-label={`${header.name} sort`}
                      {...getIconComponent(header.id)}
                    >
                      {header.name}
                    </TableSortLabel>
                  </TableCell>)
              )}
                <TableCell width={'10%'} sx={styles.tableHead}>
                  {!canViewCustomerInformation ? 
                    null :
                    <Box
                      tabIndex={0}
                      sx={{
                        textAlign: 'center',
                        marginLeft: '0.8rem',
                      }}
                    >
                      Action
                    </Box>
                  }
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={styles.tableSpacer}></TableRow>
            </TableBody>
            <TableBody>
              {customerVendorList.map((data: IARCustomer | IARVendor) => (
                <CustomerVendorRow
                  key={data.recordId}
                  customerVendor={data}
                  {...props}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
  }

  return (
    <Grid container>
      {getTableBody()}
    </Grid>
  )
}

export default CustomerVendorTable;