import { Box } from "@mui/material"
import ComboBox from "../../../components/common/combo-box"
import styles from "./styles"
import { useContext, useEffect, useState } from "react";
import { IOption } from "../../../interfaces/comboBox";
import { SelectedClientContext } from "../../../context/selectedClientContext";
import { useNavigate } from "react-router-dom";
import { CustomerSettingsContext, ICustomerSettingsContext } from "../../../context/customerSettingsContext";

export interface CustomerSettingsClientDropdownProps {
  resetSearchesAndTabs: () => void;
}

const CustomerSettingsClientDropdown = (props: CustomerSettingsClientDropdownProps) => {
  const { resetSearchesAndTabs } = props;

  const navigate = useNavigate();
  
  const selectedClientContext = useContext(SelectedClientContext);
  const {
    setSelectedARCollateral
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const [clients, setClients] = useState<IOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<IOption | null>(null);
  const [clientInput, setClientInput] = useState<string>('');

  useEffect(() => {
    const fetchedClients: IOption[] = selectedClientContext.clients
      .filter(client => !client.parentClientFk)
      .map(client => {
        const option: IOption = {
          recordId: client.recordId ?? 0,
          label: client.borrowerName ?? '',
          default: Boolean(client.default),
          parentClient: client.parentClient,
          currencyId: client.currencyId,
        };
        return option;
      });
    setClients(fetchedClients);

    const selectedClient: IOption | undefined = fetchedClients.find(client =>
      client.recordId === selectedClientContext.selectedClient?.recordId
    );
    setSelectedClient(selectedClient ?? null);
  }, [selectedClientContext.clients, selectedClientContext.selectedClient])

  const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: IOption | null) => {
    setSelectedClient(newValue);
    setSelectedARCollateral(null);
    resetSearchesAndTabs();

    const selectedClient = selectedClientContext.clients.find(client => client.recordId === newValue?.recordId);
    selectedClientContext?.setSelectedClient(selectedClient ?? null);

    if (!newValue) {
      // 0 client and 0 collateral param will trigger an empty table
      navigate(`/clients/0/settings/0/customers`);
    } else if (newValue.parentClient) {
      navigate(`/clients/${newValue?.recordId}/settings/-1/customers`);
    } else {
      // 0 collateral param will trigger the fetching of collaterals for the specific borrower
      // default collateral will be preselected and then trigger fetching of customers
      navigate(`/clients/${newValue?.recordId}/settings/0/customers`);
    }
  }

  const handleInputChange = (_event: React.SyntheticEvent<Element, Event>, newInput: string) => {
    setClientInput(newInput);
  }

  return (
    <Box sx={styles.clientBox}>
      <ComboBox
        id='client'
        options={clients}
        value={selectedClient}
        inputValue={clientInput}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </Box>
  )
}

export default CustomerSettingsClientDropdown