import GeneralBreadcrumbs from "../../../components/breadcrumb";

export interface CustomerSettingsBreadcrumbsProps {
  borrowerId?: number;
  arCollateralId?: number;
  isInEditParentChild: boolean;
  isInEditCustomerVendor: boolean;
}

const CustomerSettingsBreadcrumbs = (props: CustomerSettingsBreadcrumbsProps) => {
  const {
    borrowerId,
    arCollateralId,
    isInEditParentChild,
    isInEditCustomerVendor
  } = props;

  const linksCustomerSettings = [
    { linkText: 'Clients', route: '/clients' },
    { linkText: 'Client Settings', route: `/clients/${borrowerId}/settings` },
  ]

  const linksEditParentChild = [
    { linkText: 'Clients', route: '/clients' },
    { linkText: 'Client Settings', route: `/clients/${borrowerId}/settings` },
    { linkText: 'Customer Settings', route: `/clients/${borrowerId}/settings/${arCollateralId ?? -1}/customers` },
  ]

  if (isInEditParentChild) {
    return (
      <GeneralBreadcrumbs
        selectedText= {'Edit Parent Child Relationship'}
        breadcrumbLinks={linksEditParentChild}
      />
    )
  }

  if (isInEditCustomerVendor) {
    return (
      <GeneralBreadcrumbs
        selectedText= {'Edit Vendor and Customer Relationship'}
        breadcrumbLinks={linksEditParentChild}
      />
    );
  }

  return (
    <GeneralBreadcrumbs
      selectedText= 'Customer Settings'
      breadcrumbLinks={linksCustomerSettings}
    />
  )
}

export default CustomerSettingsBreadcrumbs