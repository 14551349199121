import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { LayoutProps } from './authContext';
import { ToasterState } from '../components/toaster';
import { IOption } from '../interfaces/comboBox';
import { customerFilters } from '../pages/customer-setting/main-view';

export interface ICustomerSettingsContext {
  canViewCustomerList: boolean,
  setCanViewCustomerList:(value: boolean) => void,
  canViewCustomerInformation: boolean,
  setCanViewCustomerInformation:(value: boolean) => void,
  canUpdateCustomerInformation: boolean,
  setCanUpdateCustomerInformation:(value: boolean) => void,
  canViewParentList: boolean,
  setCanViewParentList:(value: boolean) => void,
  canViewParentInformation: boolean,
  setCanViewParentInformation:(value: boolean) => void,
  canUpdateParentInformation: boolean,
  setCanUpdateParentInformation:(value: boolean) => void,
  canEditParentChildRelationship: boolean,
  setCanEditParentChildRelationship:(value: boolean) => void,
  toaster: ToasterState,
  setToaster: (value: ToasterState) => void,
  selectedARCollateral: IOption | null;
  setSelectedARCollateral: Dispatch<SetStateAction<IOption | null>>;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
  subTabIndex: number;
  setSubTabIndex: Dispatch<SetStateAction<number>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  totalElements: number;
  setTotalElements: Dispatch<SetStateAction<number>>;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}

export const CustomerSettingsContext = createContext<ICustomerSettingsContext | null>(null);

const CustomerSettingsProvider = (props: LayoutProps) => { 
  const { children } = props;
  
  const [ canViewCustomerList, setCanViewCustomerList ] = useState<boolean>(false);
  const [ canViewCustomerInformation, setCanViewCustomerInformation ] = useState<boolean>(false);
  const [ canUpdateCustomerInformation, setCanUpdateCustomerInformation ] = useState<boolean>(false);
  const [ canViewParentList, setCanViewParentList ] = useState<boolean>(false);
  const [ canViewParentInformation, setCanViewParentInformation ] = useState<boolean>(false);
  const [ canUpdateParentInformation, setCanUpdateParentInformation ] = useState<boolean>(true);
  const [ canEditParentChildRelationship, setCanEditParentChildRelationship ] = useState<boolean>(false);
  const [ toaster, setToaster ] = useState<ToasterState>({ open: false, message: '', severity: 'success' });
  const [ selectedARCollateral, setSelectedARCollateral ] = useState<IOption | null>(null);
  const [ tabIndex, setTabIndex ] = useState<number>(0);
  const [ subTabIndex, setSubTabIndex ] = useState<number>(0);
  const [ page, setPage ] = useState<number>(0);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(10);
  const [ totalElements, setTotalElements ] = useState<number>(0);
  const [ selectedValue, setSelectedValue ] = useState<string>(customerFilters[0]);
  const [ searchValue, setSearchValue ]  = useState<string>('');

  const value = useMemo(() => ({ 
    canViewCustomerList,
    setCanViewCustomerList,
    canViewCustomerInformation,
    setCanViewCustomerInformation,
    canUpdateCustomerInformation,
    setCanUpdateCustomerInformation,
    canViewParentList,
    setCanViewParentList,
    canViewParentInformation,
    setCanViewParentInformation,
    canUpdateParentInformation,
    setCanUpdateParentInformation,
    canEditParentChildRelationship,
    setCanEditParentChildRelationship,
    toaster,
    setToaster,
    selectedARCollateral, setSelectedARCollateral,
    tabIndex, setTabIndex,
    subTabIndex, setSubTabIndex,
    page, setPage,
    rowsPerPage, setRowsPerPage,
    totalElements, setTotalElements,
    selectedValue, setSelectedValue,
    searchValue, setSearchValue,
   }), [
    canViewCustomerList,
    setCanViewCustomerList,
    canViewCustomerInformation,
    setCanViewCustomerInformation,
    canUpdateCustomerInformation,
    setCanUpdateCustomerInformation,
    canViewParentList,
    setCanViewParentList,
    canViewParentInformation,
    setCanViewParentInformation,
    canUpdateParentInformation,
    setCanUpdateParentInformation,
    canEditParentChildRelationship,
    setCanEditParentChildRelationship,
    toaster,
    setToaster,
    selectedARCollateral, setSelectedARCollateral,
    tabIndex, setTabIndex,
    subTabIndex, setSubTabIndex,
    page, setPage,
    rowsPerPage, setRowsPerPage,
    totalElements, setTotalElements,
    selectedValue, setSelectedValue,
    searchValue, setSearchValue,
   ])

  return (
    <CustomerSettingsContext.Provider
      value={value}
    >
      {children}
    </CustomerSettingsContext.Provider>
  );
};

export default CustomerSettingsProvider;