import { FC, useEffect, useState, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, Typography } from '@mui/material';
import styles from './styles';
import { PERMISSIONS, } from '../../utility/constants';
import { getLocalStorageItem, getPermissionsOfUser} from '../../utility/helper';
import Toaster from '../../components/toaster';
import { SelectedClientContext } from '../../context/selectedClientContext';
import CustomerSettingsProvider, { CustomerSettingsContext, ICustomerSettingsContext } from '../../context/customerSettingsContext';
import EditParentChildProvider from '../../context/editParentChildContext';
import EditParentChild from './edit-parent-and-child';
import EditVendorCustomerProvider from '../../context/editVendorCustomerContext';
import EditVendorCustomer from './edit-vendor-customer';
import CustomerSettingsBreadcrumbs from './breadcrumbs';
import CustomerSettingsClientDropdown from './client-dropdown';
import CustomerSettingsCollateralDropdown from './collateral-dropdown';
import CustomerSettingsMainViewProvider from '../../context/customerSettingsMainViewContext';
import CustomerSettingsMainView from './main-view';

const customerFilters = ['All', 'New', 'Customer Name', 'Customer ID', 'Parent Name', 'Parent ID'];

/**
 * This Page displays all of the Customer Settings functionality
 */
export const CustomerSettingsPage: FC = () => {

  const {
    setCanViewCustomerList,
    setCanViewParentInformation,
    setCanViewCustomerInformation,
    setCanUpdateCustomerInformation,
    setCanUpdateParentInformation,
    setCanViewParentList,
    setCanEditParentChildRelationship,
    toaster, setToaster,
    setTabIndex,
    setSubTabIndex,
    setPage,
    setRowsPerPage,
    setTotalElements,
    setSelectedValue,
    setSearchValue,
  } = useContext(CustomerSettingsContext) as ICustomerSettingsContext;

  const { selectedClient } = useContext(SelectedClientContext);
  const [isParentView, setIsParentView] = useState<boolean>(false);
  const [isEditVendorView, setIsEditVendorView] = useState<boolean>(false);
  const [isFetchingPermissions, setIsFetchingPermissions] = useState<boolean>(false);
  const path = useLocation();

  /**
   * This useEffect determine if the user should redirect to edit parent/child page.
   */
  useEffect(() => {
    if (path.pathname.includes('/customers/edit')) {
      setIsParentView(true);
      setIsEditVendorView(false);
      return;
    }
    if (path.pathname.includes('/vendors/edit')) {
      setIsParentView(false);
      setIsEditVendorView(true);
      return;
    }
    setIsParentView(false);
    setIsEditVendorView(false);
  }, [path])

  /**
   * This useEffect triggers once to get the permissions of the current user.
   */
  useEffect(() => {
    getPermissions()
  }, [])

  /**
   * This function gets and set the current user's permissions.
   */
  const getPermissions = async () => {
    setIsFetchingPermissions(true);
    const permissions = await getPermissionsOfUser(getLocalStorageItem('uid'));
    setCanViewCustomerList(permissions.includes(PERMISSIONS.VIEW_CUSTOMER_LIST));
    setCanViewCustomerInformation(permissions.includes(PERMISSIONS.VIEW_CUSTOMER_INFORMATION));
    setCanUpdateCustomerInformation(permissions.includes(PERMISSIONS.UPDATE_CUSTOMER_INFORMATION));
    setCanViewParentList(permissions.includes(PERMISSIONS.VIEW_PARENT_LIST));
    setCanViewParentInformation(permissions.includes(PERMISSIONS.VIEW_PARENT_INFORMATION));
    setCanUpdateParentInformation(permissions.includes(PERMISSIONS.UPDATE_PARENT_INFORMATION));
    setCanEditParentChildRelationship(permissions.includes(PERMISSIONS.EDIT_PARENT_CHILD_RELATIONSHIP));
    setIsFetchingPermissions(false);
  };

  /**
   * This useCallback is used to close the toaster component controlled by CustomerSettingsContext.
   */
  const handleCloseToaster = useCallback(() => setToaster({ open: false, message: '', severity: 'success' }), []);

  
  const resetSearchesAndTabs = () => {
    setTabIndex(0);
    setSubTabIndex(0);
    setPage(0);
    setRowsPerPage(10);
    setTotalElements(0);
    setSelectedValue(customerFilters[0]);
    setSearchValue('');
  }

  const renderMainContent = () => {
    if (isFetchingPermissions) {
      return <></>
    }

    if (isParentView) {
      return (
        <EditParentChildProvider>
          <EditParentChild />
        </EditParentChildProvider>
      )
    }

    if (isEditVendorView) {
      return (
        <EditVendorCustomerProvider>
          <EditVendorCustomer />
        </EditVendorCustomerProvider>
      )
    }

    return (
      <CustomerSettingsMainViewProvider>
        <CustomerSettingsMainView />
      </CustomerSettingsMainViewProvider>
    );
  }

  return (
    <Box sx={styles.container}>
      <Box component="span" sx={styles.breadCrumbBox}>
        <Grid container sx={styles.headerContainer}>
          <Grid item xs={12} md={6} lg={8} xl={8.3}>
            <CustomerSettingsBreadcrumbs
              borrowerId={selectedClient?.recordId}
              arCollateralId={undefined}
              isInEditParentChild={isParentView}
              isInEditCustomerVendor={isEditVendorView}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3.7} sx={styles.clientDropdown}>
            <CustomerSettingsClientDropdown
              resetSearchesAndTabs={resetSearchesAndTabs}
            />
          </Grid>
        </Grid>   
      </Box>
      <Box sx={styles.titleContainer}>
        <Typography tabIndex={0} variant='h6' component='h3' sx={{...styles.title, ...styles.MarginLeft}}>
          Customer Settings
        </Typography>
      </Box>

      <Container maxWidth='xl'>
        <Box sx={styles.customerSettingsHeader}>
          {selectedClient && (
            <Grid container item xs={12} md={4} lg={3}>
              <CustomerSettingsCollateralDropdown
                resetSearchesAndTabs={resetSearchesAndTabs}
              />
            </Grid>
          )}
        </Box> 
        { renderMainContent() }
      </Container>
      <Toaster
        open={toaster.open}
        message={toaster.message}
        severity={toaster.severity}
        onCloseChange={handleCloseToaster}
      />
    </Box>
  );
};

const CustomerSettings : FC = () => {
  return (
    <CustomerSettingsProvider>
      <CustomerSettingsPage />
    </CustomerSettingsProvider>
  )
}

export default CustomerSettings;